<template>
  <el-select
          v-model="roles"
          :multiple="multiple"
          filterable
          :clearable="multiple" 
          remote
          reserve-keyword
          :placeholder="$strings.please_choose_place"
          @focus="remoteMethod('')"
          :remote-method="remoteMethod"
          :loading ="loading"
          :disabled="disabled"
        >
          <el-option
            v-for="item in options"
            :key="item[prop.value]"
            :label="item[prop.name]"
            :value="item[prop.value]">
          </el-option>
        </el-select>
</template>

<script>
let el;
export default {
  name: 'seachSelect',
  props: {
    value: {
      default:()=>[]
    },
    disabled: {
      type: Boolean,
      default:false
    },
    placeholder: {
      type: String,
      default:'请选择'
    },
    search: {
      type: Function,
      default: () => {
        return {}
      }
    },
    getKeys: {
      type: Function,
      default:()=>{}
    },
    prop: {
      type: Object,
      default: () => {
        return {
          name: 'name',
          value:'value'
        }
      }
    },
    searchKey: {
      default:''
    },
    multiple: {
      type: Boolean,
      default:false
    },
    currentProp: {
      type: String,
      default:''
    }
  },
  data: () => {
    return {
      options: [],
      loading: false,
      roles:[]
    }
  },
  created() {
    this.remoteMethod('')
    this.roles = this.value
    el =this
  },
  components: {
  },
  watch: {
    roles: function(val){
      let that = this
      that.getKeys(val,this.currentProp)
    },
    value: {
      handler() { 
        this.roles = this.value
      },
      deep:true
    }
  },
  methods: {
    remoteMethod(value) {
      let query = {}
      query[this.searchKey|| this.prop.name] =value
      this.loading = true
      this.search(query).then(response => {
        this.options = response.data.data
        this.loading = false
      }).catch(error=>{})
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/theme.scss';

</style>
