<template>
  <el-popover placement="bottom-end" popper-class="top_tag" trigger="click">
    <div slot="reference" class="lang_choose m_r_16" @click="changeIcon">
      <span>{{ langLabel }}</span>
      <tms-icon other-class="choose_right" :type="isOpen?'shang':'xia'" />
    </div>
    <div class="lang_content">
      <p v-for="item in langList" :key="item.accept" :class="`language_item ${accept===item.accept?'active_color':''}`" @click="changeLangCode(item.langCode)">
        <span>{{ item.label }}</span>
        
      </p>
    </div>
  </el-popover>
</template>
  
<script>
import website from '@/const/website'
import { getStore, setStore } from '@/util/store.js'
import { getLangList } from '@/util/language'
export default {
  name: 'Demo',
  data() {
    return {
      visible: false,
      imgUrl: website.imgUrl,
      langList: [
       
      ],
      accept: getStore({ name: 'accept' }),
      isOpen: false
    }
  },
  computed: {},
  watch: {},
  created() {
    this.langList = getLangList()
    this.getLangLabel()
  },
  methods: {
    changeLangCode(e) {
      setStore({
        name: 'langCode',
        content: e
      })
      this.getLanguage(e)
      window.location.reload()
    },
    changeIcon() {
      this.isOpen = !this.isOpen
    },
    getLangLabel() {
      const currentList = this.langList.filter(item => item.accept === this.accept)
      if (currentList.length) {
        this.langLabel = currentList[0].label
      }
    }
  }
}
</script>
  <style lang="scss">
  @import '@/styles/theme.scss';
    .lang_choose{
      width: 150px;
      height: 30px;
      line-height: 30px;
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #C9CDD4;
      display: flex;
      cursor: pointer !important;
      span{
        margin-left: 10px;
        flex: 1;
        font-size: 12px;
      }
      .choose_right{
        width: 25px;
      }
      
    }
    .lang_content{
        width: 124px;
        margin: 0 !important;
        cursor: pointer !important;
        p{
          width: 100%;
          height: 30px;
          line-height: 30px;
          padding: 7px 12px 7px 12px;
          font-size: 14px;
          @extend .font_1;
          margin: 0 !important;
        }
        p:hover{
          background: #e8efff;
          color: #165DFF;
          
        }
      }
  </style>
