import { validatenull } from './validate'
import request from '@/router/axios'
import * as CryptoJS from 'crypto-js'
import moment from 'moment'
import 'moment-timezone'

moment.tz.setDefault('Asia/Shanghai')
// 数据合并
export function mergeRecursive(source, target) {
  for (const index in target) {
    try {
      if (target[index].constructor === Object) {
        source[index] = mergeRecursive(source[index], target[index])
      } else {
        source[index] = target[index]
      }
    } catch (e) {
      source[index] = target[index]
    }
  }
  return source
}
/**
     * 小数点运算
     * @param num1
     * @param num2
     * @param type 'add'+   'subtract'-    'multiply'*   'divide' /
*/
export function mathAll(num1, num2, type) {
  let result
  const precision = Math.max(getPrecision(num1), getPrecision(num2))
  const precisionFactor = Math.pow(10, precision)
  switch (type) {
    case 'add':
      result = (parseFloat(num1) * precisionFactor + parseFloat(num2) * precisionFactor) / precisionFactor
      break
    case 'subtract':
      result = (parseFloat(num1) * precisionFactor - parseFloat(num2) * precisionFactor) / precisionFactor
      break
    case 'multiply':
      result = (parseFloat(num1) * precisionFactor * parseFloat(num2) * precisionFactor) / (precisionFactor * precisionFactor)
      break
    case 'divide':
      result = (parseFloat(num1) * precisionFactor) / (parseFloat(num2) * precisionFactor)
      break
    default:
      throw new Error('Invalid type')
  }
  return result.toString()
}

function getPrecision(num) {
  const str = String(num)
  const decimalIndex = str.indexOf('.')
  return decimalIndex === -1 ? 0 : str.length - decimalIndex - 1
}

// 小驼峰转下划线  createTime->create_time
export function camelToUnderline(str) {
  return str.replace(/([A-Z])/g, "_$1").toLowerCase()
}
/*将base64转换为file继承自blob*/
export function base64ToBlob(dataurl, filename) {
  var arr = dataurl.split(',')
  var mime = arr[0].match(/:(.*?);/)[1]/*文件类型*/
  var bstr = atob(arr[1])
  var n = bstr.length
  var u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}
// 回显数据字典
export function selectDictLabel(datas, value) {
  if (value === undefined) {
    return ''
  }
  const actions = []
  Object.keys(datas).some((key) => {
    if (datas[key].value === ('' + value)) {
      actions.push(datas[key].label)
      return true
    }
  })
  if (actions.length === 0) {
    actions.push(value)
  }
  return actions.join('')
}

// 表单序列化
export const serialize = data => {
  const list = []
  Object.keys(data).forEach(ele => {
    list.push(`${ele}=${data[ele]}`)
  })
  return list.join('&')
}
export const getObjType = obj => {
  var toString = Object.prototype.toString
  var map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}
/**
 * 对接口数据统一处理
 */
export const changeData = (data, zone) => {
  var type = getObjType(data)
  var obj
  if (type === 'array') {
    obj = []
  } else if (type === 'object') {
    obj = {}
  } else {
    // 不再具有下一层次
    if (isTimeByReg(data)) {
      data = getZoneTime(data, zone)
    }
    return data
  }
  if (type === 'array') {
    for (var i = 0, len = data.length; i < len; i++) {
     
      obj.push(changeData(data[i], zone))
    }
  } else if (type === 'object') {
    for (var key in data) {
      obj[key] = changeData(data[key], zone)
    }
  }
  return obj
}
/**
 * 对象深拷贝
 */
export const deepClone = data => {
  var type = getObjType(data)
  var obj
  if (type === 'array') {
    obj = []
  } else if (type === 'object') {
    obj = {}
  } else {
    // 不再具有下一层次
    return data
  }
  if (type === 'array') {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]))
    }
  } else if (type === 'object') {
    for (var key in data) {
      obj[key] = deepClone(data[key])
    }
  }
  return obj
}
/**
 * 判断路由是否相等
 */
export const diff = (obj1, obj2) => {
  delete obj1.close
  var o1 = obj1 instanceof Object
  var o2 = obj2 instanceof Object
  if (!o1 || !o2) {
    /*  判断不是对象  */
    return obj1 === obj2
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false
    // Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
  }

  for (var attr in obj1) {
    var t1 = obj1[attr] instanceof Object
    var t2 = obj2[attr] instanceof Object
    if (t1 && t2) {
      return diff(obj1[attr], obj2[attr])
    } else if (obj1[attr] !== obj2[attr]) {
      return false
    }
  }
  return true
}
/**
 * 设置灰度模式
 */
export const toggleGrayMode = status => {
  if (status) {
    document.body.className = document.body.className + ' grayMode'
  } else {
    document.body.className = document.body.className.replace(' grayMode', '')
  }
}
/**
 * 设置主题
 */
export const setTheme = name => {
  document.body.className = name
}

/**
 *加密处理
 */
export const encryption = params => {
  const { data, param, type } = params
  let { key } = params
  const result = JSON.parse(JSON.stringify(data))
  if (type === 'Base64') {
    param.forEach(ele => {
      result[ele] = btoa(result[ele])
    })
  } else {
    param.forEach(ele => {
      var data = result[ele]
      key = CryptoJS.enc.Latin1.parse(key)
      var iv = key
      // 加密
      var encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.NoPadding
      })
      result[ele] = encrypted.toString()
    })
  }
  return result
}
/**
 * 浏览器判断是否全屏
 */
export const fullscreenToggel = () => {
  if (fullscreenEnable()) {
    exitFullScreen()
  } else {
    reqFullScreen()
  }
}
/**
 * esc监听全屏
 */
export const listenfullscreen = callback => {
  function listen() {
    callback()
  }

  document.addEventListener('fullscreenchange', function() {
    listen()
  })
  document.addEventListener('mozfullscreenchange', function() {
    listen()
  })
  document.addEventListener('webkitfullscreenchange', function() {
    listen()
  })
  document.addEventListener('msfullscreenchange', function() {
    listen()
  })
}
/**
 * 浏览器判断是否全屏
 */
export const fullscreenEnable = () => {
  return (
    document.isFullScreen ||
    document.mozIsFullScreen ||
    document.webkitIsFullScreen
  )
}

/**
 * 浏览器全屏
 */
export const reqFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.documentElement.requestFullScreen()
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.documentElement.webkitRequestFullScreen()
  } else if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen()
  }
}
/**
 * 浏览器退出全屏
 */
export const exitFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.exitFullScreen()
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.webkitCancelFullScreen()
  } else if (document.documentElement.mozRequestFullScreen) {
    document.mozCancelFullScreen()
  }
}
/**
 * 递归寻找子类的父类
 */

export const findParent = (menu, id) => {
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].children.length !== 0) {
      for (let j = 0; j < menu[i].children.length; j++) {
        if (menu[i].children[j].id === id) {
          return menu[i]
        } else {
          if (menu[i].children[j].children.length !== 0) {
            return findParent(menu[i].children[j].children, id)
          }
        }
      }
    }
  }
}

/**
 * 动态插入css
 */

export const loadStyle = url => {
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = url
  const head = document.getElementsByTagName('head')[0]
  head.appendChild(link)
}
/**
 * 判断路由是否相等
 */
export const isObjectValueEqual = (a, b) => {
  let result = true
  Object.keys(a).forEach(ele => {
    const type = typeof a[ele]
    if (type === 'string' && a[ele] !== b[ele]) result = false
    else if (
      type === 'object' &&
      JSON.stringify(a[ele]) !== JSON.stringify(b[ele])
    )
      result = false
  })
  return result
}
/**
 * 根据字典的value显示label
 */
export const findByvalue = (dic, value) => {
  let result = ''
  if (validatenull(dic)) return value
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    let index = 0
    index = findArray(dic, value)
    if (index !== -1) {
      result = dic[index].label
    } else {
      result = value
    }
  } else if (value instanceof Array) {
    result = []
    let index = 0
    value.forEach(ele => {
      index = findArray(dic, ele)
      if (index !== -1) {
        result.push(dic[index].label)
      } else {
        result.push(value)
      }
    })
    result = result.toString()
  }
  return result
}
/**
 * 根据字典的value查找对应的index
 */
export const findArray = (dic, value) => {
  for (let i = 0; i < dic.length; i++) {
    if (dic[i].value === value) {
      return i
    }
  }
  return -1
}
/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
  let random = ''
  random = Math.ceil(Math.random() * 100000000000000)
    .toString()
    .substr(0, len || 4)
  if (date) random = random + Date.now()
  return random
}
//复制文本
export function copyText(text) {
  var element = createElement(text)
  element.select()
  element.setSelectionRange(0, element.value.length)
  element.onclick = () => {
    document.execCommand('copy')
  }
  element.click()
  element.remove()
}

//创建临时的输入框元素
function createElement(text) {
  var isRTL = document.documentElement.getAttribute('dir') === 'rtl'
  var element = document.createElement('textarea')
  // 防止在ios中产生缩放效果
  element.style.fontSize = '12pt'
  // 重置盒模型
  element.style.border = '0'
  element.style.padding = '0'
  element.style.margin = '0'
  // 将元素移到屏幕外
  element.style.position = 'absolute'
  element.style[isRTL ? 'right' : 'left'] = '-9999px'
  // 移动元素到页面底部
  const yPosition = window.pageYOffset || document.documentElement.scrollTop
  element.style.top = `${yPosition}px`
  //设置元素只读
  element.setAttribute('readonly', '')
  element.value = text
  document.body.appendChild(element)
  return element
}

/**
 *
 * @param url 目标下载接口
 * @param query 查询参数
 * @param fileName 文件名称
 * @returns {*}
 */
export function downBlobFile(url, query, fileName) {
  return request({
    url: url,
    method: 'get',
    responseType: 'blob',
    params: query
  }).then(response => {
    // 处理返回的文件流
    const blob = response.data
    if (blob && blob.size === 0) {
      this.$message.error('内容为空，无法下载')
      return
    }
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function() {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
    }, 0)
  }).catch(error => {})
}

export function getListKey(name, spliceKey) {
  const nameArr = name.split(spliceKey)
  const str = nameArr[nameArr.length - 1]
  return str
}
//filesize转化单位
export function sizeTostr(size) {
  if (!size) return '--'
  var data = ""
  size *= 1
  if (size < 1 * 1024) { //如果小于1KB转化成B  
    data = size.toFixed(2) + "B"
  } else if (size < 1 * 1024 * 1024) { //如果小于1MB转化成KB  
    data = (size / 1024).toFixed(2) + "KB"
  } else if (size < 1 * 1024 * 1024 * 1024) { //如果小于1GB转化成MB  
    data = (size / (1024 * 1024)).toFixed(2) + "MB"
  } else { //其他转化成GB  
    data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB"
  }
  var sizestr = data + ""
  var len = sizestr.indexOf("\.")
  var dec = sizestr.substr(len + 1, 2)
  if (dec === "00") { //当小数点后为00时 去掉小数部分  
    return sizestr.substring(0, len) + sizestr.substr(len + 3, 2)
  }
  return sizestr
}  
/**
 * 获取时间区域
 * @param durting /时间区间 默认为7
 * @returns {*}
 */
export function getDurDate(durting = 7) {
  const timeArr = []
  const formatStr = 'yyyy-MM-DD'
  timeArr.push(moment().subtract(durting - 1, 'days').format(formatStr))
  timeArr.push(moment().format(formatStr))
  return timeArr
}  
/**
 * 获取时间区域
 * @param durting /时间区间 默认为7
 * @returns {*}
 */
export function getDurDateArr(durting = 7) {
  const timeArr = []
  const formatStr = 'yyyy-MM-DD'
  for (let index = durting - 1; index >= 0; index--) {
    timeArr.push({
      date: moment().subtract(index, 'days').format(formatStr),
      count: 0
    })
  }
  return timeArr
}  
export function isTimeByReg(time) {
  const reg = /^((\d{2}(([02468][048])|([13579][26]))[\-\/\s]?((((0?[13578])|(1[02]))[\-\/\s]?((0?[1-9])|([1-2][0-9])|(3[01])))|(((0?[469])|(11))[\-\/\s]?((0?[1-9])|([1-2][0-9])|(30)))|(0?2[\-\/\s]?((0?[1-9])|([1-2][0-9])))))|(\d{2}(([02468][1235679])|([13579][01345789]))[\-\/\s]?((((0?[13578])|(1[02]))[\-\/\s]?((0?[1-9])|([1-2][0-9])|(3[01])))|(((0?[469])|(11))[\-\/\s]?((0?[1-9])|([1-2][0-9])|(30)))|(0?2[\-\/\s]?((0?[1-9])|(1[0-9])|(2[0-8]))))))(\s((([0-1][0-9])|(2?[0-3]))\:([0-5]?[0-9])((\s)|(\:([0-5]?[0-9])))))+$/g
  return reg.test(time)
}
export function isDateByReg(time) {
  const reg = /^[1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/
  return reg.test(time)
}
export function getZoneTime(time, zone) {
  return moment(time).utcOffset(zone.split('UTC')[1]).format("YYYY-MM-DD HH:mm:ss")
}


/**
 * 列表结构转树结构
 * @param data
 * @param id
 * @param parentId
 * @param children
 * @param rootId
 * @returns {*}
 */
export function handleTree(data, id, parentId, children, rootId) {
  id = id || 'id'
  parentId = parentId || 'parentId'
  children = children || 'children'
  rootId = rootId || Math.min.apply(Math, data.map(item => {
    return item[parentId]
  })) || 0
  //对源数据深度克隆
  const cloneData = JSON.parse(JSON.stringify(data))
  //循环所有项
  const treeData = cloneData.filter(father => {
    const branchArr = cloneData.filter(child => {
      //返回每一项的子级数组
      return father[id] === child[parentId]
    })
    branchArr.length > 0 ? father[children] = branchArr : ''
    //返回第一层
    return father[parentId] === rootId
  })
  return treeData !== '' ? treeData : data
}
