<template>
  <div class="map_search_wrap l_control">
    <loadJs :url="mapLoadUrl"  />
    <el-autocomplete  
    class="map_Input"
    v-model="content"
    :fetch-suggestions="querySearchAsync"
    :placeholder="$strings.please_input_place"
    @select="handleSelect"/>
  </div>
</template>
<script>
import { getStore } from '@/util/store.js'
import loadJs from '../loadJs/index.vue'
export default {
  name: 'mapSearch',
  components: {
    loadJs
  },
  props: {
    setPoint: {
      type: Function,
      default:()=>{}
    }
  },
  data() {
    return {
      placeSearch: null,
      content: '',
      timout:null
    }
  },
  computed: {
    mapLoadUrl() {
      if (getStore({ name: 'langCode' }) === 'zh_CN') {
        return `https://webapi.amap.com/maps?v=2.0&key=${this.key || 'de4d9f99cee4befcf0fb29b517f9006e'}`
      } else {
        return `https://maps.googleapis.com/maps/api/js?key=${this.key || 'AIzaSyBpZj0ckdMN3Qttn5p-dNlKnaP53ftpLhI'}&libraries=places`
      }
    }
  },
  watch: {
    
  },
  created() {
    
  },
  beforeDestroy() {
    this.timout&&clearTimeout(this.timout)
  },
  mounted() {
    document.getElementsByClassName('map_search_wrap')[0].addEventListener('click', (e) => {
      e.stopPropagation()
      e.preventDefault()
    })
  },
  methods: {
    getResultByGoogle(queryString, cb) {
      const request = {
        query: queryString,
        fields: ['name', 'formatted_address','geometry'],
      }
      const service =new google.maps.places.PlacesService(  document.createElement('div'))
      service.findPlaceFromQuery(request, (result) => {
        if (!result) {
              cb([])
            return
          } 
        const resultList= result.map(item => {
            return {
              value:item.name,
              location: {
                lng: item.geometry.location.lng(),
                lat: item.geometry.location.lat(),
              }
            }
          })
          cb(resultList)
      })
    },
    getResultByAmap(queryString, cb) {
      AMap.plugin('AMap.AutoComplete', () =>{
        this.placeSearch = new AMap.AutoComplete({
          city: '全国'//返回中国地址信息
        })
        this.placeSearch.search(queryString, (status, result) => {
          if (!result.tips) {
              cb([])
            return
          } 
          const resultList= result.tips.map(item => {
            const {id,name,location} = item
            return {
              id,
              value:name,
              location
            }
          })
          cb(resultList)
          // 查询成功时，result即对应匹配的POI信息
       });
      })
    
      
    },
    querySearchAsync(queryString, cb) {
      if (!queryString) {
          cb([]) 
          return
        }
      this.timout && clearTimeout(this.timout)
      this.timout = setTimeout(() => {
        getStore({ name: 'langCode' }) === 'zh_CN'? this.getResultByAmap(queryString,cb): this.getResultByGoogle(queryString,cb)
      },500)
      
    },
    handleSelect(item) {
      const {lng,lat} = item.location
      this.setPoint({
          lng,lat
      })
    }
    
    
  }
}
</script>
<style lang="scss">
@import '@/styles/theme.scss';

.map_search_wrap{
  height: 32px;
  line-height: 32px;
  justify-content: space-between;
  position: relative;
  margin-left: 40px;
  margin-top: 40px;
  
  
}
.el-autocomplete{
    .el-input{
      width: 260px !important;
      .el-input__inner{
        @extend .bac_w;
      }
    }
  }
</style>
