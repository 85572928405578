var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-popover",
        {
          attrs: {
            "popper-class": "top_tag",
            placement: "bottom",
            trigger: "click",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "circle_32 li_h_32 m_r_16",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [_c("tms-icon", { attrs: { type: "zhanghao font_3 font_s_32" } })],
            1
          ),
          _c("div", { staticClass: "avactor_content" }, [
            _c("p", { on: { click: _vm.changePwd } }, [
              _vm._v(_vm._s(_vm.$strings.change_pwd)),
            ]),
            _c("p", { on: { click: _vm.logout } }, [
              _vm._v(_vm._s(_vm.$strings.logout)),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }