import request from '@/router/axios'

export function fetchList(query) {
  return request({
    url: '/admin/bizUser/page',
    method: 'get',
    params: query
  })
}

export const getPwdRules = () => {
  return request({
    url: '/admin/bizUser/getPwdStrengthRule',
    method: 'get'
  })
}

export function addObj(obj) {
  return request({
    url: '/admin/bizUser',
    method: 'post',
    data: obj
  })
}

export function getObj(id) {
  return request({
    url: '/admin/bizUser/' + id,
    method: 'get'
  })
}

export function delObj(id) {
  return request({
    url: '/admin/bizUser/' + id,
    method: 'delete'
  })
}

export function putObj(obj) {
  return request({
    url: '/admin/bizUser',
    method: 'put',
    data: obj
  })
}
export function upDateStatus(obj) {
  return request({
    url: '/admin/bizUser/updateStatus',
    method: 'put',
    data: obj
  })
}
export function resetPwd(id, obj) {
  return request({
    url: '/admin/bizUser/resetPwd/' + id,
    method: 'put',
    data: obj
  })
}
export function getDetails(obj) {
  return request({
    url: '/admin/bizUser/details/' + obj,
    method: 'get'
  })
}


export function getDetailsByPhone(obj) {
  return request({
    url: '/admin/bizUser/detailsByPhone/' + obj,
    method: 'get'
  })
}

// 更改个人信息
export function editInfo(obj) {
  return request({
    url: '/admin/bizUser/edit',
    method: 'put',
    data: obj
  })
}
// 忽略修改密码
export function ignore() {
  return request({
    url: '/admin/bizUser/ignoreChangePwd',
    method: 'PUT'
  })
}
// 绑定邮箱
export function bindEmail(obj) {
  return request({
    url: '/admin/bizUser/bindUserEmail',
    method: 'PUT',
    data: obj
  })
}

// 发送验证码
export function sendCode(query) {
  return request({
    url: '/admin/bizUser/sendLoginValidateCodeMail',
    method: 'get',
    params: query
  })
}

// 获取email
export function getEmail(query) {
  return request({
    url: '/admin/bizUser/getUserEmail',
    method: 'get',
    params: query
  })
}
// 获取email
export function getMfaUrl(username) {
  return request({
    url: '/admin/user/googleMfa/generateAuthUrl/' + username,
    method: 'get'
  })
}
export function getForgetCode(username) {
  return request({
    url: '/admin/bizUser/sendForgetPwdMail/' + username,
    method: 'get'
  })
}
export function changeForgetPwd(data) {
  return request({
    url: '/admin/bizUser/changePwdViaValidateCode',
    method: 'put',
    data
  })
}
