<template>
  <div :class="`simple_tree ${otherName} ${rightType === 'simple'||rightType === 'includes'?'bac_white':''}`" :style="{'width':innerWidth}">
    <div class="tree_title just_between">
      <div class="font_1"><el-checkbox class="m_r_4 font_s_14" v-show="showCheckbox" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"></el-checkbox> {{ leftTitle }}</div>
      <div v-if="rightType === 'count'" class="font_3 font_s_12">{{`${checkedKeys.length}/${orgDataIds.length}`}}</div>
      <div v-else-if="rightType === 'delete'" class="cur_po font_3 font_s_12" @click="deleteAll">{{$strings.delete}}</div>
      <div v-else-if="rightType === 'includes'" class="cur_po font_3 font_s_12" @click="chooseAll"><icon :title="includes?$strings.show_current:$strings.show_all" :type="includes?'bubaohanziji':'baohanziji' " /> </div>
      <div v-else="rightType === 'simple'" ></div>
    </div>
    <div class="tree_search">
      <el-input v-emoji suffix-icon="el-icon-search" :placeholder="$strings.please_input_place" v-model="filterText"/>
    </div>
    <div class="tree_content" :style="{ 'height':innerPropHeight?innerPropHeight: innerHeight-164+'px'}">
      <el-tree
        ref="tree"
        :props="defaultProps"
        :data="data"
        node-key="id"
        :default-expand-all="true"
        :expand-on-click-node="false"
        :show-checkbox="showCheckbox"
        :default-checked-keys="checkedKeys"
        :filter-node-method="filterNode"
        :renderContent="renderContent"
        @node-click="nodeClick"
        :highlight-current="true"
        @check-change="handleCheckChange">
        
      </el-tree>
    </div>
  </div>
</template>
  
<script>
import icon from '../icon/index.vue'
import { mapGetters } from 'vuex'
import { getStore } from '@/util/store.js'
let el;
export default {
  name: 'simpleTree',
  props: {
    innerWidth: {
      default:'423px'
    },
    innerPropHeight: {
      default:''
    },
    showCheckbox: {
      default:false
    },
    leftTitle: {
      default:'',
    },
    rightType: {
      default: 'count',
      // delete 删除按钮
      //count  树选中计数 deleteAll为删除后回调（必传）
    },
    data: {
      type:Array,
      default: () => {
        return [{
          id: 1,
          name: '一级 1',
          children: [{
            id: 4,
            name: '二级 1-1',
            children: [{
              id: 9,
              name: '三级 1-1-1'
            }, {
              id: 10,
              name: '三级 1-1-2'
            }]
          }]
        }, {
          id: 2,
          name: '一级 2',
          children: [{
            id: 5,
            name: '二级 2-1'
          }, {
            id: 6,
            name: '二级 2-2'
          }]
        }, {
          id: 3,
          name: '一级 3',
          children: [{
            id: 7,
            name: '二级 3-1'
          }, {
            id: 8,
            name: '二级 3-2'
          }]
        }]
      }
    },
    otherName: {
      default:''
    },
    removeByKey: {
      default:()=>{}
    },
    needCode: {
      default:false
    }
  },
  components: {
    icon
  },
  data() {
    return {
      visible: false,
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      checkedKeys: [],
      checkAll: false,
      isIndeterminate: false,
      includes:false
    }
  },
  computed: {
    ...mapGetters([
      'userInfo',
      'innerHeight',
    ]),
    orgDataIds: () => {
      let orgIds = []
      let getChildren = (list) => {
        list.forEach(item => {
          orgIds.push(item.id)
          if (item.children) {
            getChildren(item.children)
          }
        })
      }
      getChildren(el.data)
      return orgIds
    }
  },
  watch: {
    filterText(val) {//搜索过滤
      this.$refs.tree.filter(val)
    },
    checkedKeys(val) {//全选按钮状态判断
      this.checkAll = val.length !== 0 //有选中key
      this.isIndeterminate = val.length > 0 && val.length < this.orgDataIds.length //当未全选时，状态修改
      this.$refs.tree.setCheckedKeys(val) //根据数据实时修改树状态
      this.$emit('getTreeByCheckeds',this.data,val) //父组件获取树选中keys
    }
  },
  mounted() {
    if (this.rightType === 'simple'||this.rightType === 'includes') {
      setTimeout(() => {
        const { deptId } = this.userInfo
        this.$refs.tree&&this.$refs.tree.setCurrentKey(deptId) 
        this.$emit('nodeclick',deptId,getStore({name:'deptName'}))
      },600) 
    }
  },
  created() {
    el = this 
    
  },
  methods: {
    //选中树的checkbox
    handleCheckChange() {
      this.checkedKeys = this.$refs.tree.getCheckedKeys()
    },
    //树包含下级存储
    chooseAll() {
      
      this.includes = !this.includes
      this.$emit('nodeScope',this.includes)
    },
    //搜索过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    //全选按钮点击
    handleCheckAllChange(val) {
      //获取点击后的选中key集合
      let currentKeys = val ? this.orgDataIds : [];
      this.checkedKeys = currentKeys
      this.$refs.tree.setCheckedKeys(currentKeys)
      this.isIndeterminate = false;
    },
    deleteAll() {
      //标题右侧为删除按钮时回调
      this.$emit('deleteAll')
    },
    //设置树选中key
    setCheckedKeys(currentKeys) {
      this.$refs.tree.setCheckedKeys(currentKeys)
    },
    //点击树节点
    nodeClick(row) {
      this.$refs.tree.setCurrentKey(row.id)
      this.$emit('nodeclick',row.id,row.name)
    },
    removeItem(e, key) {
      e.preventDefault()
      this.removeByKey(key)
    },
    renderContent(h, { node, data, store }) {
      let iDomType = ''
        return (
          <span class="custom-tree-node">
            <span> {this.rightType==='simple'?<i class={`iconfont icon-${iDomType}`} />:'' } {this.needCode? this.$strings[data.code]:data.name}</span>
            {this.rightType==='delete'&&!data.children?<span class='cur_po danger_color' type="text" on-click={ (e) => this.removeItem( e,data.id) }>
              {this.$strings.delete}
            </span>:''}
          </span>);
      }
  }
}
</script>
  <style lang="scss">
  @import '@/styles/theme.scss';
    .simple_tree{
      .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
        background: #e8efff !important;
      }
      @extend .border_3;
      .tree_title{
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding: 0 8px;
        @extend .bac;
      }
      .tree_search{
        height: 44px;
        // width: 407px;
        margin: 8px 8px 0 8px;
        input{
          height: 28px;
          line-height: 28px;
          @extend .bac_1;
          // width: 407px;
          
        }
        .el-input__icon{
          @extend .font_2
        }

      }
      .tree_content{
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 10px;
        .is-checked{
          color: #165DFF;
        }
        .el-tree-node__expand-icon{
          @extend .font_2
        }
        .el-tree-node__expand-icon.is-leaf {
            color: transparent;
            cursor: default;
        }
        
      }
    }
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
      }
    .bac_white{
      height: 100%;
      @extend .bac_w;
      .tree_title{
        @extend .bac_w;
        border-bottom: 1px solid;
        @extend .border_3;
      }
      .tree_search input{
          height: 28px;
          line-height: 28px;
          // width: 407px;
          
        }
    }
  </style>
