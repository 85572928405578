var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tms-dialog",
    {
      ref: "dialog",
      attrs: {
        title: _vm.$strings.change_pwd,
        width: "639px",
        "before-close": _vm.close,
      },
      on: { submit: _vm.submit, cancel: _vm.close },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form_input",
          attrs: {
            "label-position": "right",
            rules: _vm.rules,
            model: _vm.form,
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$strings.ori_pwd, prop: "oldPassword" } },
            [
              _c(
                "el-input",
                {
                  directives: [{ name: "emoji", rawName: "v-emoji" }],
                  attrs: {
                    type: _vm.oldpasswordType,
                    placeholder: _vm.$strings.please_input_place,
                  },
                  model: {
                    value: _vm.form.oldPassword,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "oldPassword",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.oldPassword",
                  },
                },
                [
                  _c("i", {
                    class: `iconfont ${
                      _vm.oldpasswordType
                        ? "icon-yincangmima"
                        : "icon-xianshimima"
                    } font_2`,
                    attrs: { slot: "suffix" },
                    on: {
                      click: function ($event) {
                        return _vm.showPassword("old")
                      },
                    },
                    slot: "suffix",
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$strings.new_pwd, prop: "password" } },
            [
              _c(
                "el-input",
                {
                  directives: [{ name: "emoji", rawName: "v-emoji" }],
                  attrs: {
                    type: _vm.currentpasswordType,
                    placeholder: _vm.$strings.please_input_place,
                  },
                  model: {
                    value: _vm.form.password,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "password",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.password",
                  },
                },
                [
                  _c("i", {
                    class: `iconfont ${
                      _vm.currentpasswordType
                        ? "icon-yincangmima"
                        : "icon-xianshimima"
                    } font_2`,
                    attrs: { slot: "suffix" },
                    on: {
                      click: function ($event) {
                        return _vm.showPassword("current")
                      },
                    },
                    slot: "suffix",
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$strings.repeat_pwd, prop: "repeatPassword" },
            },
            [
              _c(
                "el-input",
                {
                  directives: [{ name: "emoji", rawName: "v-emoji" }],
                  attrs: {
                    type: _vm.repeatpasswordType,
                    placeholder: _vm.$strings.please_input_place,
                  },
                  model: {
                    value: _vm.form.repeatPassword,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "repeatPassword",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.repeatPassword",
                  },
                },
                [
                  _c("i", {
                    class: `iconfont ${
                      _vm.repeatpasswordType
                        ? "icon-yincangmima"
                        : "icon-xianshimima"
                    } font_2`,
                    attrs: { slot: "suffix" },
                    on: {
                      click: function ($event) {
                        return _vm.showPassword("repeat")
                      },
                    },
                    slot: "suffix",
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }