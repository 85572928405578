var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      directives: [
        { name: "show", rawName: "v-show", value: false, expression: "false" },
      ],
      ref: "input",
      attrs: {
        id: `select${new Date() * 1}`,
        accept: _vm.accept,
        type: "file",
      },
      on: { change: _vm.handleFiles },
    }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "screen_all",
        staticClass: "screen_all",
      },
      [
        _vm.loadingObj.percent
          ? _c("div", { staticClass: "screen_upload_wrap" }, [
              _c("div", { staticClass: "just_center" }, [
                _c(
                  "div",
                  { staticClass: "m_r_12" },
                  [
                    _c("el-progress", {
                      attrs: {
                        type: "circle",
                        color: _vm.theme,
                        percentage: _vm.loadingObj.percent,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", [
                  _c("p", { staticClass: "tal li_h_40" }, [
                    _vm._v(
                      _vm._s(_vm.loadingObj.uploadedSize) +
                        " / " +
                        _vm._s(this.loadingObj.totalSize)
                    ),
                  ]),
                  _c("p", { staticClass: "tal li_h_40" }, [
                    _vm._v(
                      _vm._s(_vm.$strings.current_speed) +
                        ":" +
                        _vm._s(_vm.loadingObj.speed)
                    ),
                  ]),
                  _c("p", { staticClass: "tal" }, [
                    _vm._v(
                      _vm._s(_vm.$strings.remaining_time) +
                        ":" +
                        _vm._s(_vm.loadingObj.remainingTime)
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        !_vm.loadingObj.percent
          ? _c("div", { staticClass: "screen_upload_wrap font_s_16" }, [
              _c("i", { staticClass: "el-icon-loading font_s_24" }),
              _c("br"),
              _c("span", [_vm._v(_vm._s(_vm.$strings.file_parsing))]),
              _vm._v(" "),
              _c("br"),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }