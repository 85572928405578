<template>
  <canvas :id="id" :width="width" :height="width"></canvas>
</template>
  
<script>
let el;
export default {
  name: 'tmsDialog',
  props: {
    id: {
      type: String,
      default:'current'
    },
    width: {
      type: String,
      default: '54'
    },
    imgWidth: {
      type: String,
      default: '30'
    },
    imgSrc:{
      type: String,
      default:''
    },
    num: {
      type: Number,
      default:36
    },
    radius: {
      type: Number,
      default:53
    },
    circleColor: {
      type: String,
      default:'#E8F3FF'
    }
  },
  data() {
    return {
      visible:false
    }
  },
  computed: {
    
  },
  watch: {
    num: function(val)  {
      this.canvasId&&this.changeCanvas()
    },
    circleColor:function (val) {
      this.canvasId&&this.changeCanvas()
    }
  },
  created() {
    el = this
  },
  mounted() {
    this.changeCanvas()
  },
  methods: {
    changeCanvas() {
      this.canvasId = document.getElementById(this.id)
      this.context = this.canvasId.getContext("2d")          //获取2d上下文
      this.getPixelRatio()
      const currentWidth = this.width.split('px')[0]
      this.cirY=this. cirX = (currentWidth/ 2) * this.ratio                    //canvas x轴的中点
      this.canvasId.style.width = this.width + 'px'
      this.canvasId.style.height = this.width + 'px'
      this.canvasId.width = this.width * this.ratio;
      this.canvasId.height = this.width * this.ratio;
      this.rad = ((Math.PI * 2) * (1/100) )                    //360度的百分之一
      this.drawImage()
      this.drawCircleLine()
    },
    drawImage() {
      this.img = new Image()
      this.img.onload = () => {
        //第二种绘制图片方式 (第一组：绘制起点位置，第二组：绘制长宽)
        this.context.drawImage(this.img, this.cirX-(this.imgWidth/2*this.ratio),this.cirX-(this.imgWidth/2*this.ratio),this.imgWidth*this.ratio,this.imgWidth*this.ratio)
      }
      this.img.src = this.imgSrc
    },
    drawCircleLine() {
      //外圆
      let endAngle = 360 * this.rad
      this.context.beginPath()
      this.context.strokeStyle = '#F6F7FB'
      this.context.lineWidth = 1*this.ratio
      this.context.arc(this.cirX, this.cirY, (this.radius/2)*this.ratio, 0, endAngle)
      this.context.closePath()
      this.context.stroke()
      //内圆
      this.context.beginPath()
      this.context.strokeStyle = this.circleColor
      this.context.lineWidth = 27*this.ratio
      this.context.arc(this.cirX, this.cirY, 13*this.ratio,-Math.PI/2, ((Math.PI * 2) * (this.num/100) ) - Math.PI / 2)
      this.context.stroke()
    },
    getPixelRatio() {
      //处理模糊比例
      const backingStore=this.context.backingStorePixelRatio ||
      this.context.webkitBackingStorePixelRatio ||
      this.context.mozBackingStorePixelRatio || this.context.msBackingStorePixelRatio ||
      this.context.oBackingStorePixelRatio || this.context.backingStorePixelRatio || 1;
      this.ratio = (window.devicePixelRatio || 1) / backingStore
      
    }
  }
}
</script>

