var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.needElement
        ? _c(
            "el-upload",
            {
              ref: "upload",
              attrs: {
                accept: _vm.accept,
                fileList: _vm.fileList,
                "show-file-list": false,
                "http-request": _vm.handleFiles,
                listType: "object",
                action: "",
                drag: "",
              },
            },
            [
              _c(
                "div",
                { staticClass: "add_file_icon" },
                [
                  _c("icon", {
                    attrs: { type: "xinzeng", "other-class": "m_b_20" },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v(" " + _vm._s(_vm.$strings.upload_text_drag) + " "),
                _c("div", { staticClass: "limit_text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$insertMsg(
                        _vm.$strings.upload_size_insert,
                        "854*480",
                        "png/jpg"
                      )
                    ) + " "
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      !_vm.needElement
        ? _c("input", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false",
              },
            ],
            ref: "input",
            attrs: {
              id: `uploader${new Date() * 1}`,
              accept: _vm.accept,
              type: "file",
            },
            on: { change: _vm.handleFiles },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }