<script>
import RenderColumn from "./render-column";
import TableSetting from "./table-setting.vue";
import { mapGetters } from "vuex";
let COLUMN_KEY_ID = 1;

export default {
  name: "tms-table",
  components: { RenderColumn, TableSetting },
  data() {
    return {
      page: {
        // 当前页改变和页面size改变
        pageSize: 10,
        currentPage: 1,
      },
      tableSettingDialogVisible: false,
      selectedShowColumns: [], // 选择展示的列
      heightNum:window.innerHeight
    };
  },
  provide() {
    return {
      tableRoot: this,
      heightNum:window.innerHeight
    };
  },
  computed: {
    ...mapGetters([
      'innerHeight',
      'innerWidth',
      'userInfo'
    ])
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    hideColumns: {
      // 需要隐藏的列，label名称
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    columnsKeyName: null,
    align: {
      type: String,
      default: "left",
    },
    showPagination: {
      // 是否展示分页控件
      type: Boolean,
      default: true,
    },
    tableHeight: {
      default:''
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSizes: {
      type: Array,
      default: () => [10, 50, 100, 200],
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    layout: {
      type: String,
      default: "total, prev, pager, next,sizes",
    },
    total: {
      type: Number,
      default: 0,
    },
    paginationStyle: {
      type: Object,
      default() {
        return {
          "margin-top": "16px",
        };
      },
    },
    selectable: {
      // 单选按钮是否可勾选
      type: Function,
      default: () => true,
    },
    showOverflowTooltip: {
      // 全局行配置，内容过多是否展示省略
      type: Boolean,
      default: true,
    },
    showTableSetting: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: Array,
      default: () => ["tms-table-wrapper"],
    },
    selectList:{
      type: Array
    },
    currentChange: {
      type: Function,
      default: () => {},
    }
  },
  created() {
    this.$nextTick(() => {
      let num;
      num = this.innerHeight - this.$refs.table.$el.offsetTop - 86
      if (!this.showPagination) {
        num += 52
      }
      if (document.getElementById('tms_footer').childNodes.length){
          num-=67
        }
      this.heightNum = num
      this.toggleList(this.selectList)
    })
    this.initSelectedShowColumns();
  },
  watch: {
    currentPage(val) {
      // 当前currentPage 需是data属性
      this.page.currentPage = val;
    },
    hideColumns() {
      this.initSelectedShowColumns()
    },
    columns: {
      handler: function(val) {
        this.initSelectedShowColumns();
      },
      deep:true
    },
    selectList:{
      handler: function (val) {
        this.toggleList(val);
      },
      deep:true
    },
    innerHeight() {
      if (this.$refs.table) {
        let num;
        num = this.innerHeight - this.$refs.table.$el.offsetTop - 86
        if (!this.showPagination) {
          num += 52 //52 为分页器总高度
        }
        if (document.getElementById('tms_footer').childNodes.length){
          num-=55
        }
        this.heightNum = num
      }
    }
  },
  methods: {
    //回显选中态
    toggleList(addIds) {
      this.tableData.forEach(item => {
        if (addIds&&addIds.includes(item.id)) {
          this.$refs.table.toggleRowSelection(item,true);
        }
      })
    },
     // 设置列的排序为我们自定义的排序
    handleHeaderClass(item) {
      const {column} = item
      if (column.sortable == "custom" &&column.myorder) {
        column.order = column.myorder;
       }
    },

    handleSizeChange(val) {
      this.page.pageSize = val;
      this.$emit("size-change", val);
      // 当前页改变和页面size改变
      this.$emit("page-change", this.page);
    },
    handleCurrentChange(val) {
      this.currentChange(val)
      this.page.currentPage = val;
      // 当前页改变和页面size改变
      this.$emit("page-change", this.page);
    },
    openColumnSettingDialog() {
      this.tableSettingDialogVisible = true;
    },
    initSelectedShowColumns() {
      this.selectedShowColumns = this.columns.filter(
        (column) => !this.hideColumns.includes(column.label)
      );
    },
   
    click() {
      
    },
    confirmTableSetting(checkedColumns) {
      this.selectedShowColumns.forEach((item) => {
        if (checkedColumns.includes(item.label)) {
          item.hide = false;
        } else {
          if (item.label) {
            item.hide = true;
          }
        }
      });
      this.tableSettingDialogVisible = false;
    },
  },
  render() {
    const that = this;
    return (
      <div style="overflow: hidden">
        {that.showTableSetting && (
          <div class="table-setting" on-click={that.openColumnSettingDialog}>
            <i class="el-icon-setting"></i>
          </div>
        )}
        <el-table
          header-row-class-name="tms-table-header"
          data={this.tableData}
          height={this.tableHeight|| (this.heightNum + 'px')}
          empty-text={this.$strings.no_data}
          ref="table"
          header-cell-class-name={this.handleHeaderClass}

          {...{
            props: this.$attrs,
            on: this.$listeners,
          }}
        >
          {this.selectedShowColumns.map((column) => {
            return column.hide ? null : (
              <RenderColumn
                key={column.prop ? column.prop : ++COLUMN_KEY_ID}
                column={column}
                align={this.align}
                showOverflowTooltip={this.showOverflowTooltip}
              />
            );
          })}
        </el-table>

        {that.showPagination && (
          // 分页组件
          <div class="tms-table-pagination" style={that.paginationStyle}>
            <el-pagination
              vOn:size-change={that.handleSizeChange}
              vOn:current-change={that.handleCurrentChange}
              {...{
                on: {
                  "update:currentPage": (val) => {
                    that.page.currentPage = val
                  },
                },
              }}
              pageSizes={that.pageSizes}
              pageSize={that.pageSize}
              layout={that.layout}
              current-page={that.currentPage}
              total={that.total}
            ></el-pagination>
          </div>
        )}

        <table-setting
          visible={that.tableSettingDialogVisible}
          columnOptions={that.columns}
          hideColumns={that.hideColumns}
          {...{
            on: {
              "update:visible": (val) => (that.tableSettingDialogVisible = val),
              confirm: that.confirmTableSetting,
            },
          }}
        />
      </div>
    );
  },
};
</script>

<style lang="scss">
</style>
