var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tms-dialog",
    {
      ref: "dialog",
      attrs: {
        title: _vm.title,
        width: "960px",
        "before-close": _vm.close,
        "submit-flag": false,
        "cancel-title": _vm.$strings.close,
        "append-to-body": true,
      },
      on: { cancel: _vm.close },
    },
    [
      _c("div", { staticClass: "ql-container ql-snow" }, [
        _c("div", {
          staticClass: "ql-editor",
          domProps: { innerHTML: _vm._s(_vm.content) },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }