var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dis_flex hei_100" },
    [
      _vm._t("tree"),
      _c("div", { staticClass: "flex_col flex_1" }, [
        _c(
          "div",
          {
            staticClass: "flex_1",
            style: {
              "max-width": _vm.tabWidth + "px",
              "min-width": _vm.minWidth + "px",
            },
          },
          [_vm._t("default")],
          2
        ),
        _c(
          "div",
          { staticClass: "footer_right_bottom", attrs: { id: "tms_footer" } },
          [_vm._t("footer")],
          2
        ),
      ]),
      _c("Home", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasHome && _vm.webConfig.flag,
            expression: "hasHome&&webConfig.flag",
          },
        ],
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }