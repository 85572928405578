var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tms-dialog",
    {
      ref: "dialog",
      attrs: {
        title: _vm.$strings.email_info,
        width: "439px",
        "before-close": _vm.close,
        "show-close": false,
        "cancel-flag": false,
        "has-footer": false,
      },
      on: { submit: _vm.submit, cancel: _vm.close },
    },
    [
      _vm.codeFlag
        ? _c("div", { staticClass: "m_b_20" }, [
            _vm._v(
              _vm._s(_vm.$insertMsg(_vm.$strings.bind_email_message, _vm.email))
            ),
          ])
        : _vm._e(),
      !_vm.codeFlag
        ? _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form_input",
              attrs: {
                "label-position": "right",
                rules: _vm.rules,
                model: _vm.form,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$strings.bind_email, prop: "email" } },
                [
                  _c("el-input", {
                    directives: [{ name: "emoji", rawName: "v-emoji" }],
                    attrs: { placeholder: _vm.$strings.please_input_place },
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "email",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.codeFlag
        ? _c(
            "el-form",
            {
              ref: "code_form",
              staticClass: "form_input",
              attrs: {
                "label-position": "right",
                rules: _vm.codeRules,
                model: _vm.codeForm,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$strings.vail_date_code, prop: "code" } },
                [
                  _c(
                    "div",
                    { staticClass: "dis_flex" },
                    [
                      _c("el-input", {
                        directives: [{ name: "emoji", rawName: "v-emoji" }],
                        attrs: { placeholder: _vm.$strings.please_input_place },
                        model: {
                          value: _vm.codeForm.code,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.codeForm,
                              "code",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "codeForm.code",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "code_interval" },
                        [
                          _vm.codeCount
                            ? _c("span", [_vm._v(_vm._s(_vm.codeCount))])
                            : _vm._e(),
                          _vm.codeCount === 0
                            ? _c("tms-icon", {
                                attrs: { type: "xunhuan" },
                                on: { click: _vm.changeFlag },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "wid_100 dis_flex" },
        [
          !_vm.codeFlag
            ? _c(
                "el-button",
                {
                  staticClass: "mar_0_auto",
                  attrs: { type: "primary" },
                  on: { click: _vm.nextCode },
                },
                [_vm._v(_vm._s(_vm.$strings.next_step))]
              )
            : _vm._e(),
          _vm.codeFlag
            ? _c(
                "el-button",
                {
                  staticClass: "mar_0_auto",
                  attrs: { type: "primary" },
                  on: { click: _vm.submit },
                },
                [_vm._v(_vm._s(_vm.$strings.submit))]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }