var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout" }, [
    _c(
      "div",
      {
        staticClass: "layout_left flex_col",
        style: { "min-width": _vm.keyCollapse ? "64px" : "247px" },
      },
      [
        _vm.keyCollapse
          ? _c(
              "div",
              {
                staticClass:
                  "hei_60 font_1 li_h_60 font_w_500 font_s_20 cur_po",
                on: { click: _vm.goToHome },
              },
              [
                _c("img", {
                  staticClass: "img_logo",
                  attrs: { src: _vm.webConfig.closingMenuBar },
                }),
              ]
            )
          : _vm._e(),
        !_vm.keyCollapse
          ? _c(
              "div",
              {
                staticClass:
                  "hei_60 font_1 li_h_60 font_w_500 font_s_20 cur_po",
                on: { click: _vm.goToHome },
              },
              [
                _c("img", {
                  staticClass: "img_all_logo",
                  attrs: { src: _vm.webConfig.expendingMenuBar },
                }),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "over_y_auto over_x_hid m_r_2",
            style: { height: _vm.innerHeight - 100 + "px" },
          },
          [_c("Sidebar", { ref: "menu" })],
          1
        ),
        _c(
          "div",
          { class: `flex_1 m_t_12  ${_vm.keyCollapse ? "tac" : "m_r_20 tar"}` },
          [
            _c("tms-icon", {
              attrs: {
                type: _vm.keyCollapse ? "zhankai1" : "shouqi",
                "other-class": "",
                func: _vm.changeCollapse,
              },
            }),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "layout_right flex_col",
        style: { "min-width": _vm.rightWidth + "px" },
      },
      [
        _c(
          "div",
          {
            staticClass: "hei_60 bac_w border_3 border_b_1_s",
            style: { width: "100%" },
          },
          [_c("Top")],
          1
        ),
        _c(
          "div",
          {
            staticClass: "flex_1 padd_b_0 flex_col wid_100",
            style: { overflow: "auto" },
          },
          [
            _c(
              "div",
              { staticClass: "flex_1" },
              [
                _c(
                  "keep-alive",
                  [_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
                  1
                ),
                !_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }