<template>
  <div
    :class="{'basic-container--block':block}"
    class="basic-container padd_b_0  padd_20">
    <el-card>
      <slot />
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'BasicContainer',
  props: {
    block: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.basic-container {
  border-radius: 10px;
  flex: 1;
  box-sizing: border-box;
  .el-card {
    width: 100%;
    box-shadow: none !important;
  }
  &--block {
    height: 100%;
    .el-card {
      height: 100%;
    }
  }
}
</style>
