<template>
  <tms-dialog
    ref="dialog"
    :title="title"
    width="960px"
    :before-close="close"
    :submit-flag="false"
    :cancel-title="$strings.close"
    @cancel="close"
  >
  <tms-searchAlone :search="refresh" :reset="reset" class="m_b_20">
        <div slot="input">
          <el-form ref="search_form" label-position="right" class="m_r_36 form_input_169" :model="searchForm" :inline="true" label-width="80px">
            <el-form-item :label="this.$strings.title">
              <el-input v-model="searchForm.title" v-emoji :placeholder="this.$strings.title"></el-input>
            </el-form-item>
            <el-form-item :label="$strings.info_type" class="m_b_0">
              <el-select v-model="searchForm.type" :placeholder="$strings.please_input_place">
                <el-option :key="1" :label="$strings.type_info" :value="1"></el-option>
                <el-option :key="2" :label="$strings.type_active" :value="2"></el-option>
                <el-option :key="3" :label="$strings.type_message" :value="3"></el-option>
              </el-select>
            
            </el-form-item>
          </el-form>
        </div>
      </tms-searchAlone>
    <tms-table 
      v-loading="loading"
      :table-data="list"
      :columns="columns"
      :show-pagination="true"
      :page-size="page.pageSize"
      :total="page.total"
      :table-height="innerHeight-263-60-108+'px'"
      :current-page="page.currentPage"
      row-key="userId"
      :current-change="getList"
      @size-change="sizeChange"
    />
      <DialogQuil ref="dialog_quil" />
  </tms-dialog>
</template>
  
<script>
import {getStore} from '@/util/store'
import {mapGetters} from 'vuex'
import tmsIconList from '../iconList'
import DialogQuil from './dialogQuil.vue'
export default {
    name: "DialogDetail",
    props: {
        title: {
            type: String,
            default: ""
        },
        fetchDetail: {
            type: Function,
            default: () => { }
        }
    },
    comments: {
        tmsIconList,
        DialogQuil
  },
  computed: {
    ...mapGetters(['innerHeight'])
    },
    data() {
        return {
            loading: false,
            visible: false,
            page: {
                total: 0,
                currentPage: 1,
                pageSize: 10,
                isAsc: false // 是否倒序
            },
            list: [],
            searchForm: {
                title: "",
                type: ""
            },
            columns: [
                {
                    label: this.$strings.title,
                    prop: "title",
                    fixed: "left"
                },
                {
                    label: this.$strings.type,
                    prop: "type",
                    render: (row) => {
                        let typeDom;
                        switch (row.type) {
                            case 1:
                                typeDom = <span>{this.$strings.type_info}</span>;
                                break;
                            case 2:
                                typeDom = <span>{this.$strings.type_active}</span>;
                                break;
                            case 3:
                                typeDom = <span>{this.$strings.type_message}</span>;
                                break;
                            default:
                                break;
                        }
                        return typeDom;
                    }
                },
                {
                    label: this.$strings.update_time,
                    prop: "updateTime"
                },
                {
                    label: this.$strings.operate,
                    prop: "operate",
                    fixed: "right",
                    render: (row) => {
                        return <tms-iconList iconList={this.getIconList(row)} iconLength={3}/>;
                    }
                }
            ],
            date: ""
        };
    },
    methods: {
        show(date) {
            this.$refs.dialog.visible = true;
            // this.date = date
            this.getList();
        },
        close() {
            this.$refs.dialog.visible = false;
            this.page = {
                total: 0,
                currentPage: 1,
                pageSize: 10,
                isAsc: false // 是否倒序
            };
        },
        getList(currentPage) {
            this.loading = true;
            this.fetchDetail(Object.assign(this.searchForm, {
                current: currentPage || this.page.currentPage,
              size: this.page.pageSize,
              langCode:getStore({ name: 'accept' })
            })).then(response => {
                this.list = response.data.data.records;
                this.page.total = response.data.data.total;
                currentPage && (this.page.currentPage = currentPage);
                this.loading = false;
            }).catch(error=>{});
        },
        sizeChange(val) {
            this.page.pageSize = val;
            this.getList(1);
        },
        reset() {
            this.searchForm = {
                title: "",
                type: ""
            };
            this.refresh();
        },
        refresh() {
            this.page.currentPage = 1;
            this.getList(1);
        },
        getIconList(row) {
            return [
                {
                    type: "a-chakanxinxiruanjianxinxixiangqingtuisongrenwuxiangqing",
                    title: this.$strings.detail,
                    flag: true,
                event: () => {
                        this.$refs.dialog_quil.show(row.id);
                    }
                }
            ];
        },
    },
    components: { DialogQuil }
}
</script>

