var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "tms-dialog",
        {
          ref: "dialog_tms",
          attrs: { width: "887px", title: _vm.title },
          on: { cancel: _vm.cancel, submit: _vm.submit },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "just_between",
            },
            [
              _c("simpleTree", {
                ref: "all_choose_tree",
                attrs: {
                  leftTitle: _vm.$strings.all_permission,
                  showCheckbox: true,
                  data: _vm.roleMenu,
                  otherName: "border_tree",
                  needCode: true,
                  innerPropHeight: _vm.innerPropHeight,
                },
                on: { getTreeByCheckeds: _vm.getTreeByCheckeds },
              }),
              _c("simpleTree", {
                attrs: {
                  leftTitle: _vm.$strings.checked_permission,
                  rightType: "delete",
                  otherName: "border_tree border_left_none",
                  data: _vm.dataList,
                  needCode: true,
                  removeByKey: _vm.removeByKey,
                  innerPropHeight: _vm.innerPropHeight,
                },
                on: { deleteAll: _vm.deleteAll },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }