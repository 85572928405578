import request from '@/router/axios'
import qs from 'qs'
import store from '@/store'
import { getStore, setStore } from '@/util/store.js'
import { validatenull } from '@/util/validate'
import website from '@/const/website.js'

const scope = 'server'

export const loginByUsername = (username, password, code, randomStr, validateCode, googleMfaCode) => {
  const grant_type = 'password'
  const dataObj = qs.stringify({ username: username, password: password })
  const basicAuth = 'Basic ' + window.btoa(website.formLoginClient)

  // 保存当前选中的 basic 认证信息
  setStore({
    name: 'basicAuth',
    content: basicAuth,
    type: 'session'
  })

  return request({
    url: '/auth/oauth2/token',
    headers: {
      isToken: false,
      'TENANT-ID': '0',
      Authorization: basicAuth
    },
    method: 'post',
    noloading: true,
    params: { randomStr, code, grant_type, scope, validateCode, googleMfaCode },
    data: dataObj
  })
}

export const refreshToken = (refresh_token) => {
  const grant_type = 'refresh_token'
  // 获取当前选中的 basic 认证信息
  const basicAuth = getStore({ name: 'basicAuth' })

  return request({
    url: '/auth/oauth2/token',
    headers: {
      isToken: false,
      'TENANT-ID': '0',
      Authorization: basicAuth
    },
    method: 'post',
    noloading: true,
    params: { refresh_token, grant_type, scope }
  })
}

export const loginByMobile = (mobile, code) => {
  const grant_type = 'mobile'
  const basicAuth = 'Basic ' + window.btoa(website.smsLoginClient)

  // 保存当前选中的 basic 认证信息
  setStore({
    name: 'basicAuth',
    content: basicAuth,
    type: 'session'
  })

  return request({
    url: '/auth/oauth2/token',
    headers: {
      isToken: false,
      'TENANT-ID': '0',
      Authorization: basicAuth
    },
    method: 'post',
    params: { mobile: 'SMS@' + mobile, code: code, grant_type, scope }
  })
}

export const loginBySocial = (state, code) => {
  const grant_type = 'mobile'
  const basicAuth = 'Basic ' + window.btoa(website.socialLoginClient)

  // 保存当前选中的 basic 认证信息
  setStore({
    name: 'basicAuth',
    content: basicAuth,
    type: 'session'
  })

  return request({
    url: '/auth/oauth2/token',
    headers: {
      isToken: false,
      'TENANT-ID': '0',
      Authorization: basicAuth
    },
    method: 'post',
    params: { mobile: state + '@' + code, code: code, grant_type, scope }
  })
}

export const getUserInfo = () => {
  return request({
    url: '/admin/user/info',
    method: 'get'
  })
}



export const logout = () => {
  return request({
    url: '/auth/token/logout',
    method: 'delete'
  })
}

//获取多语言接口
export const postlanguage = (data) => {
  return request({
    url: '/admin/translation/listItem',
    method: 'post',
    data
  })
}

/**
 * 校验令牌，若有效期小于半小时自动续期
 * @param refreshLock
 */
export const checkToken = (refreshTime, refreshLock, $store) => {
  const token = store.getters.access_token
  // 获取当前选中的 basic 认证信息
  const basicAuth = getStore({ name: 'basicAuth' })

  request({
    url: '/auth/token/check_token',
    headers: {
      isToken: false,
      Authorization: basicAuth
    },
    method: 'get',
    params: { token }
  })
    .then((response) => {
      if (validatenull(response)) {
        clearInterval(refreshTime)
        return
      }

      const expire = response && response.data && response.data.exp
      if (expire) {
        const expiredPeriod = expire * 1000 - new Date().getTime()
        //小于半小时自动续约
        if (expiredPeriod <= 10 * 60 * 1000) {
          if (!refreshLock) {
            refreshLock = true
            $store.dispatch('RefreshToken').catch(() => {
              clearInterval(refreshTime)
            })
            refreshLock = false
          }
        }
      }
    })
    .catch((error) => {
    })
}

/**
 * 注册用户
 */
export const registerUser = (userInfo) => {
  return request({
    url: '/admin/register/user',
    method: 'post',
    data: userInfo
  })
}
