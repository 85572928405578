var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.title
    ? _c("i", {
        class: `iconfont tms_icon font_2 icon-${_vm.type} ${_vm.otherClass}`,
        on: { click: _vm.getEvent },
      })
    : _vm.title
    ? _c(
        "el-tooltip",
        {
          attrs: {
            content: _vm.title,
            placement: "bottom-start",
            "visible-arrow": false,
          },
        },
        [
          _c("i", {
            class: `iconfont  tms_icon icon-${_vm.type} ${_vm.otherClass}`,
            on: { click: _vm.getEvent },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }