<template>
  <i v-if="!title"  @click="getEvent" :class="`iconfont tms_icon font_2 icon-${type} ${otherClass}`"></i>
  <el-tooltip   v-else-if="title" :content="title" placement="bottom-start" :visible-arrow="false">
    <i  @click="getEvent" :class="`iconfont  tms_icon icon-${type} ${otherClass}`"></i>
  </el-tooltip>
</template>
  
<script>
let el;
export default {
  name: 'tmsIcon',
  props: {
    type: {
      type: String,
      default: 'edit'
    },
    otherClass: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    func: {
      type: Function,
      default: ()=>{}
    },
  },
  data() {
    return {
      visible:false
    }
  },
  computed: {
  },
  watch: {},
  created() {
    el = this
  },
  methods: {
    getEvent() {
      this.func()
      this.$emit('click')
    }
  }
}
</script>
  <style lang="scss" >
  @import '@/styles/theme.scss';
  .tms_icon{
    font-size: 12px;
    @extend .font_2;
    width: 12px;
    font-size: 12px;
    cursor: pointer;
  }
  .tms_card_icon{
    padding: 10px;
    @extend .bac_1;
  }
  </style>
