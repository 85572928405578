import Vue from 'vue'
import Clipboard from 'clipboard'
import { getStore } from './store'
const ori_strings = getStore({ name: 'langMap' })
function clipboardSuccess(strings) {
  Vue.prototype.$message.success(strings.copy_sucss)
}

function clipboardError(strings) {
  Vue.prototype.$message.error(strings.copy_fail)
}

export default function handleClipboard(text, event, strings = ori_strings) {
  const clipboard = new Clipboard(event.target, {
    text: () => text
  })
  clipboard.on('success', () => {
    clipboardSuccess(strings)
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    clipboardError(strings)
    clipboard.destroy()
  })
  clipboard.onClick(event)
}
