<template>
  <tms-dialog
    ref="dialog"
    :title="$strings.email_info"
    width="439px"
    :before-close="close"
    :show-close="false"
    :cancel-flag="false"
    :has-footer="false"
    @submit="submit"
    @cancel="close"
  >
    <div v-if="codeFlag" class="m_b_20">{{ $insertMsg($strings.bind_email_message,email) }}</div>
    <el-form v-if="!codeFlag" ref="form" label-position="right" :rules="rules" class=" form_input" :model="form">
      <el-form-item :label="$strings.bind_email" prop="email">
        <el-input v-model.trim="form.email" v-emoji :placeholder="$strings.please_input_place">
        </el-input>
      </el-form-item>
    </el-form>
    <el-form v-if="codeFlag" ref="code_form" label-position="right" :rules="codeRules" class=" form_input" :model="codeForm">
      <el-form-item :label="$strings.vail_date_code" prop="code">
        <div class="dis_flex">
          <el-input v-model.trim="codeForm.code" v-emoji :placeholder="$strings.please_input_place" />
          <div class="code_interval">
            <span v-if="codeCount">{{ codeCount }}</span>
            <tms-icon v-if="codeCount===0" type="xunhuan" @click="changeFlag" />
          </div> 
        </div>
      </el-form-item>
    </el-form>
    <div class="wid_100 dis_flex">
      <el-button v-if="!codeFlag" class="mar_0_auto" type="primary" @click="nextCode">{{ $strings.next_step }}</el-button>
      <el-button v-if="codeFlag" class="mar_0_auto" type="primary" @click="submit">{{ $strings.submit }}</el-button>
    </div>
  </tms-dialog>
</template>
  
<script>
import { isEmail } from '@/util/validate'
import { sendCode, bindEmail } from '@/api/admin/user'
import { mapGetters } from 'vuex'
import { aesEncrypt } from '@/util/encryption'
export default {
  name: 'DialogChangePwd',
  props: {
    refresh: {
      type: Function,
      default: () => {}
    },
    name: {
      type: String,
      default: ''
    },
    callBack: {
      type: Function,
      default: null
    }
  },
  data() {
    const validatorEmail = (rule, value, callback) => {
      if (!isEmail(value) && value) {
        return callback(new Error(this.$insertMsg(this.$strings.validator_sure, this.$strings.email)))
      } else {
        callback()
      }
    }
    return {
      codeFlag: false,
      codeCount: 60,
      email: '',
      form: {
        email: ''
      },
      codeForm: {
        code: ''
      },
      rules: {
        email: [
          { required: true, message: this.$insertMsg(this.$strings.please_input, this.$strings.email), trigger: 'blur' },
          { validator: validatorEmail, trigger: 'blur' }
        ]
      },
      codeRules: {
        code: [
          { required: true, message: this.$insertMsg(this.$strings.please_input, this.$strings.vail_date_code), trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'website'])
  },
  watch: {
    
  },
  created() {
    
  },
  beforeDestroy() {
    this.interval && clearInterval(this.interval)
  },
  mounted() {
   
  },
  methods: {
    show(infoObj) {
      const { email, username } = infoObj
      if (username) {
        this.username = username
      } else {
        const { username } = this.userInfo
        this.username = username
      }
      if (email) {
        this.email = email
        this.changeFlag()
      }

      this.$refs.dialog.visible = true
    },
    encry(email) {
      return aesEncrypt(email, this.website.encPassword)
    },
    close() {
      this.form = {
        email: ''
      }
      this.codeForm = {
        code: ''
      }
      this.$refs.dialog.visible = false
      this.codeCount = 0
    },
    submit() {
      if (this.callBack) {
        this.callBack({ validateCode: this.codeForm.code }, this.close)
      } else {
        const { userId } = this.userInfo
        const formObj = {
          userId,
          email: this.encry(this.form.email),
          validateCode: this.codeForm.code
        }
        bindEmail(formObj).then(res => {
          this.close()
        }).catch(error => {})
      }
      
    },
    nextCode() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.email = this.form.email 
          this.changeFlag()
        } 
      })
    },
    changeFlag() {
      
      this.codeFlag = true
      this.codeCount = 60
      if (!this.interval) {
        this.sendEmailCode()
        this.interval = setInterval(() => {
          this.codeCount = this.codeCount === 0 ? 0 : this.codeCount - 1 
        }, 1000)
      } else {
        clearInterval(this.interval)
        this.interval = null
        this.changeFlag()
      }
    },
    sendEmailCode() {
      const codeQuery = {
        username: this.username
      }
      if (this.form.email) {
        codeQuery.email = this.form.email
      }
      sendCode(codeQuery).catch(error => {})
      
    }

  }
}
</script>
  <style lang="scss">
  @import '@/styles/theme.scss';
  .code_interval{
    @extend .bac_primary;
    @extend .font_5;
    // width: 36px;
    height: 32px;
    border: 0;
    padding: 0 12px;
    border-radius: 4px;
    text-align: center;
    line-height: 32px;
    margin-left: 10px;
    cursor: pointer;
    i{
      @extend .font_5;
    }
  }
  </style>

