export default {
  title: 'https://uitmss3.eu.aw-iot.com/tms/tms-web-ui/images/all_angle.png',
  subtitle: 'https://uitmss3.eu.aw-iot.com/tms/tms-web-ui/images/angle.png',

  homeLogo: 'https://uitmss3.eu.aw-iot.com/tms/tms-web-ui/images/angel_text.png',
  icon: '',
  loginBoxLogo: '',
  background: 'https://uitmss3.eu.aw-iot.com/tms/tms-web-ui/images/login_banner.png',
  icpFilingNumber: 'Copyright 2023 XC TECH All rights reserved ',
  webTitle: 'Angel Watch',
  titleIcon: 'https://uitmss3.eu.aw-iot.com/tms/tms-web-ui/images/favicon.ico',
  expendingMenuBar: 'https://uitmss3.eu.aw-iot.com/tms/tms-web-ui/images/all_angle.png',
  closingMenuBar: 'https://uitmss3.eu.aw-iot.com/tms/tms-web-ui/images/angle.png',

  isFirstPage: true, // 配置首页不可关闭
  key: 'xc', // 配置主键,目前用于存储
  encPassword: 'xcxcxcxcxcxcxcxc', // 前后端数据传输的密钥
  validateCode: true, //是否开启验证码校验
  passwordEnc: true, //是否开启密码加密传输
  register: true, //是否开启注册
  connectSync: false, //是否开启互联同步
  domainAutoTenant: false, // 是否根据 domain 自动匹配租户
  formLoginClient: 'xc:xc', // 用户名密码登录的 client 信息
  smsLoginClient: 'app:app', // 验证码登录的 client 信息
  socialLoginClient: 'social:social', // 社交登录的 client 信息
  websocket: false, // 是否开启websocket
  dynamicCodeCache: false, //  是否开启动态代码缓存
  whiteList: ['/login', '/404', '/401', '/lock'], // 配置无权限可以访问的页面
  whiteTagList: ['/login', '/404', '/401', '/lock'], // 配置不添加tags页面 （'/advanced-router/mutative-detail/*'——*为通配符）
  manualUrl: 'https://docs.qq.com/doc/DZFB1SFdBaEpZVHRO',
  fistPage: {
    label: '首页',
    value: '/index',
    params: {},
    query: {},
    group: [],
    close: false
  },
  // 配置菜单的属性
  menu: {
    props: {
      label: 'label',
      path: 'path',
      icon: 'icon',
      children: 'children',
      id: 'id'
    }
  },
  language: {
    base: '1643797255081111553',
    current: '1639150236497567745'
  },
  imgUrl: 'https://uitmss3.eu.aw-iot.com/tms/tms-web-ui/images/'
}
