
function initChinaProvider() {
  L.TileLayer.ChinaProvider = L.TileLayer.extend({

  initialize: function(type, options) { // (type, Object)
      var providers = L.TileLayer.ChinaProvider.providers;

      options = options || {}

      var parts = type.split('.');

      var providerName = parts[0];
      var mapName = parts[1];
      var mapType = parts[2];

      var url = providers[providerName][mapName][mapType];
      options.subdomains = providers[providerName].Subdomains;

      L.TileLayer.prototype.initialize.call(this, url, options);
  }
});

L.TileLayer.ChinaProvider.providers = {
  TianDiTu: {
      Normal: {
          Map: "//t{s}.tianditu.com/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk={key}",
          Annotion: "//t{s}.tianditu.com/DataServer?T=cva_w&X={x}&Y={y}&L={z}&tk={key}"
      },
      Satellite: {
          Map: "//t{s}.tianditu.com/DataServer?T=img_w&X={x}&Y={y}&L={z}&tk={key}",
          Annotion: "//t{s}.tianditu.com/DataServer?T=cia_w&X={x}&Y={y}&L={z}&tk={key}"
      },
      Terrain: {
          Map: "//t{s}.tianditu.com/DataServer?T=ter_w&X={x}&Y={y}&L={z}&tk={key}",
          Annotion: "//t{s}.tianditu.com/DataServer?T=cta_w&X={x}&Y={y}&L={z}&tk={key}"
      },
      Subdomains: ['0', '1', '2', '3', '4', '5', '6', '7'],
      key: "174705aebfe31b79b3587279e211cb9a"
  },

  GaoDe: {
      Normal: {
          Map: '//webrd0{s}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}'
      },
      Satellite: {
          Map: '//webst0{s}.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}',
          Annotion: '//webst0{s}.is.autonavi.com/appmaptile?style=8&x={x}&y={y}&z={z}'
      },
      RealTime: {
          Map: '//tm.amap.com/trafficengine/mapabc/traffictile?v=1.0&;t=1&x={x}&y={y}&z={z}'
      },
      Subdomains: ["1", "2", "3", "4"]
  },


  OSM: {
      Normal: {
          Map: "//{s}.tile.osm.org/{z}/{x}/{y}.png",
      },
      Subdomains: ['a', 'b', 'c']
  },

};

L.tileLayer.chinaProvider = function(type, options) {
  return new L.TileLayer.ChinaProvider(type, options);
};
}
export {initChinaProvider}

