import axios from 'axios'
import website from '@/const/website'
import { serialize } from '@/util'
import { changeData } from '@/util/util'
import { aesDecrypt, aesEncrypt } from '@/util/encryption'
import { getStore } from '@/util/store'
import NProgress from 'nprogress' // progress bar
import errorCode from '@/const/errorCode'
// eslint-disable-next-line
import router from '@/router/router'
import { Message, MessageBox } from 'element-ui'
// import 'nprogress/nprogress.css'
import qs from 'qs'
import store from '@/store' // progress bar style

import { Loading } from 'element-ui'
let loadingInstance = null
function loadingShowOrHide(show) {
  if (show) {
    loadingInstance = Loading.service({ fullscreen: true })
    // utils.showLoading()
  } else {
    loadingInstance && loadingInstance.close()
    // utils.hideLoading()
  }
}
axios.defaults.timeout = 50000
// 返回其他状态吗
axios.defaults.validateStatus = function(status) {
  return status >= 200 && status <= 500 // 默认的
}
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true
// NProgress Configuration
NProgress.configure({
  showSpinner: false
})

// HTTPrequest拦截
axios.interceptors.request.use(config => {
  loadingShowOrHide(config.method.toLowerCase() !== 'get' && !config.noloading)
  !config.hidenProgress && NProgress.start() // start progress bar
  const TENANT_ID = getStore({ name: 'tenantId' })
  const isToken = (config.headers || {}).isToken === false
  const token = store.getters.access_token
  const accept = getStore({ name: 'accept' }) 
  const timeZone = store.getters.userInfo.timeZone
  const MAP_TYPE = accept.includes("zh-CN") ? 'amap' : 'osm'
  config.headers['platform'] = 'tmsWebUi'// 平台标识
  if (token && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + token// token
  }
  if (TENANT_ID && !website.domainAutoTenant) {
    config.headers['TENANT-ID'] = TENANT_ID
  } else {
    config.headers['TENANT-ID'] = 0// 租户ID
  }
  
  if (timeZone) {
    config.headers['Time-Zone'] = timeZone//zone
  }
  if (MAP_TYPE) {
    config.headers['MAP-TYPE'] = MAP_TYPE
  }
  if (accept) {
    config.headers['Accept-Language'] = accept
  }

  // headers中配置serialize为true开启序列化
  if (config.method === 'post' && config.headers.serialize) {
    config.data = serialize(config.data)
    delete config.data.serialize
  }

  // 请求报文加密
  if (config.headers['Enc-Flag']) {
    config.data = {
      encryption: aesEncrypt(JSON.stringify(config.data), website.encPassword)
    }
  }

  if (config.method === 'get') {
    config.paramsSerializer = function(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }
  return config
}, error => {
  // return Promise.reject(error)
})

// HTTPresponse拦截
axios.interceptors.response.use(res => {
  NProgress.done()
  const { config } = res
  config.method.toLowerCase() !== 'get' && !config.noloading && loadingShowOrHide(false)
  const status = Number(res.status) || 200
  const timeZone = store.getters.userInfo.timeZone || 'UTC+08:00'
  const message = res.data.msg || errorCode[status] || errorCode['default']
  const langStrings = getStore({ name: 'langMap' }) 

  // 后定义 424 针对令牌过去的特殊响应码
  if (status === 424) {
    if (window.messageBoxFlag) return //只允许一个重新登录框存在
    window.messageBoxFlag = MessageBox.confirm(langStrings.token_over, langStrings.prompt, {
      confirmButtonText: langStrings.relgn,
      cancelButtonText: langStrings.cancel,
      type: 'warning'
    })
      .then(() => {
        store.dispatch('LogOut').then(() => {
          window.messageBoxFlag = null
          // 刷新登录页面，避免多次弹框
          window.location.reload()
        })
      })
      .catch(() => { window.messageBoxFlag = null })
    return
  }
  if ((status !== 200 && message !== 'login_need_validate_code' && message !== 'bad_validate_code' && message !== 'login_need_google_mfa_code' && message !== 'login_need_bind_google_secret_key' && message !== 'bad_google_mfa_code') ||
    (res.data.code === 1 && message !== 'login_need_google_mfa_code' && message !== 'login_need_validate_code' && message !== 'bad_validate_code' && message !== 'login_need_bind_google_secret_key' && message !== 'bad_google_mfa_code')) {
    
    Message({
      message: message,
      type: 'error'
    })
    // return
    return Promise.reject(new Error(message))
  }

  // 针对密文返回解密
  if (res.data.encryption) {
    const originData = JSON.parse(aesDecrypt(res.data.encryption, website.encPassword))
    res.data = originData
    return res
  }
  if (res.data.data) {
    res.data.data = changeData(res.data.data, timeZone)
  }
  return res
}, error => {
  // 处理 503 网络异常
  if (error.response.status === 503) {
    Message({
      message: error.response.data.msg,
      type: 'error'
    })
  }
  NProgress.done()
  // return Promise.reject(new Error(error))
})

export default axios
