<template>
  <div class="just_between ">
    <div class="flex_1 search_min">
      <slot name="input"></slot>
    </div>
    <div class="search_right_more border_l_1_s tms_button">
      <div><el-button type="primary" class="m_b_20" @click="search">{{ $strings.search }}</el-button></div>
      <div><el-button  @click="reset"> {{ $strings.reset }}</el-button></div>
    </div>
  </div>
</template>

<script>
import icon from '../icon/index.vue'
export default {
  name: 'BasicContainer',
  props: {
    search: {
      type: Function,
      default:()=>{}
    },
    reset: {
      type: Function,
      default:()=>{}
    }
  },
  components: {
    icon
  },
  methods: {
    searchList() {
      this.search&&this.search(1)
    },
    resetSearch() {
      this.reset && this.reset()
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/theme.scss';
@import '@/styles/layout.scss';
.search_right_more{
  width: 120px;
  min-width: 120px;
  // @extend .just_end;
  padding-left: 20px;
  @extend .border_3;
  border-left-width: 2px !important;
  button{
    height: 32px;
  }
}
</style>
