<template>
  <div class="dis_flex hei_100 " >
    <slot name="tree"></slot>
    <div class="flex_col flex_1 ">
      <div class="flex_1" :style="{ 'max-width': tabWidth+'px','min-width':minWidth+'px'}">
        <slot></slot>
      </div>
      
      <div class="footer_right_bottom" id="tms_footer"><slot name="footer" class="footer_right_bottom" ></slot></div>
      
    </div>
    <Home v-show="hasHome&&webConfig.flag"/>
  </div>
  
</template>
  
<script>
// import Footer from '@/components/bottom'
import Home from './Home'
import {mapGetters} from 'vuex'
let el;
export default {
  name: 'tmsLayout',
  components: {
    // Footer,
    Home
  },
  props: {
    hiddenFooter: {
      type: Boolean,
      default:false
    },
    hasHome: {
      type: Boolean,
      default:false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'keyCollapse',
      'innerHeight',
      'innerWidth',
      'subWidth',
      'webConfig'
    ]),
    tabWidth() {
      let width =this.keyCollapse?this.innerWidth - 64: this.innerWidth - 247
      if (this.$slots.tree) width -=220
      if (this.hasHome&&this.webConfig.flag) width -= 300
      // if (width < 920) {
      //   width=920
      // }
      return width
    },
    minWidth() {
      const innerWid  = 1200
      let width =this.keyCollapse?innerWid - 64: innerWid - 247
      if (this.$slots.tree) width -=220
      if (this.hasHome&&this.webConfig.flag) width -= 300
      // if (width < 920) {
      //   width=920
      // }
      return width
    }
  },
  watch: {
    
  },
  created() {
    el = this
  },
  mounted() {
    
  },
  methods: {
    getInner() {
      this.innerWidth = window.innerWidth
      this.innerHeight = window.innerHeight
      
    }
  }
}
</script>
  <style lang="scss" >
  </style>
