<template>
  <div>
    <input :id="`select${new Date()*1}`" ref="input" v-show="false" :accept="accept" type="file" @change="handleFiles" >
    <div ref="screen_all" class="screen_all" 
    v-show="loading"
     >
     <div class="screen_upload_wrap  " v-if="loadingObj.percent">
      <div class="just_center ">
        <div class="m_r_12">
          <el-progress type="circle" :color="theme" :percentage="loadingObj.percent"></el-progress>
        </div>
        <div>
          <p class="tal  li_h_40">{{ loadingObj.uploadedSize }} / {{ this.loadingObj.totalSize }}</p>
          <p class="tal li_h_40">{{ $strings.current_speed }}:{{ loadingObj.speed }}</p>
          <p class="tal ">{{$strings.remaining_time}}:{{ loadingObj.remainingTime }}</p>
        </div>
      </div>
  
     </div>
     <div v-if="!loadingObj.percent" class="screen_upload_wrap font_s_16">
      <i class="el-icon-loading font_s_24" /><br/>
      <span>{{$strings.file_parsing}}</span> <br/>
    </div>
    
    </div>
  </div>
    
</template>
<script >
import { parseXml } from '@/api/ota/manage';
import {mapGetters} from 'vuex'
var minioClient;
export default {
  props: {
    accept: {
      type: String,
      default:''
    }
  },
  data() {
    return {
     
      loading: false,
      loadingObj: {},
    }
  },
  computed: {
    ...mapGetters(['theme'])
  },
  created() {
    
  },
  mounted(){
    document.body.append(this.$refs['screen_all'])
  },
  beforeDestroy() {
    
  },
  methods: {
    getFile() {
      this.$refs.input.click()
    },
/**
 * 上传逻辑处理，如果文件已经上传完成（完成分块合并操作），则不会进入到此方法中
 */
    handleUpload(file, taskRecord) {
      this.loading = true
      let params = new FormData();
      params.append("file", file);
      parseXml(params).then(res => {
        this.loading = false
        res.data.data.fileName = file.name
        this.$emit('getParse',res.data.data)
      }).catch(error => {
        this.clearInput()
        this.loading = false
      })
      
    },
    async handleFiles(e) {//文件上传
      const file = e.target.files[0]
      this.handleUpload(file)
      
    },
    
    clearInput() {
      this.$refs.input.value=''
    },
    delete() {
      this.clearInput()
    }
    
  }
}
</script>
<style lang="scss">
@import '@/styles/theme.scss';
.screen_all{
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3001;
  .screen_upload_wrap{
    width: 500px;
    @extend .font_5;
    margin: 50vh auto;
    text-align: center;
    transform: translateY(-50%);
    .el-progress__text{
      @extend .font_5;
    }
    p{
      margin: 0 !important;
    }
  }
  .close_upload{
    position: absolute;
    font-size: 24px;
    right: 30px;
    top: 30px;
  }
}
</style>