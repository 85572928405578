<template>
  <div class="just_between search_form" :style="{minWidth:'650px'}">
    <div class="flex_1" >
      <slot name="input"></slot>
    </div>
    <div class="search_right_alone border_l_1_s tms_button">
      <el-button type="primary" @click="searchList"> {{ $strings.search }}</el-button>
      <el-button @click="resetSearch"> {{ $strings.reset }}</el-button>
      
      <br/>
      <div class="tabel_oprate m_t_20 " v-if="exportFlag" @click="exportFun">
        <tms-icon type="piliangdaochu" :title="$strings.all_export" />
      </div>
    </div>
  </div>
</template>

<script>
import icon from '../icon/index.vue'
export default {
  name: 'searchAlone',
  props: {
    search: {
      type: Function,
      default:()=>{}
    },
    reset: {
      type: Function,
      default:()=>{}
    },
    exportFun: {
      type: Function,
      default:()=>{}
    },
    exportFlag: {
      type: Boolean,
      default:false
    }
  },
  components: {
    icon
  },
  methods: { 
    searchList() {
      this.search&&this.search()
    },
    resetSearch() {
      this.reset && this.reset()
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/theme.scss';
@import '@/styles/layout.scss';
.search_form{
  .el-form-item--small.el-form-item{
    margin-bottom: 0px !important;
  }
  .el-form{
      margin-top: -10px;
  }
}
.search_right_alone{
  @extend .just_end;
  padding-left: 16px;
  @extend .border_3;
  border-left-width: 2px !important;
  margin-left: 16px;
  flex-wrap: wrap;
  min-width: 256px;
  
}
.tms_button{
  .el-button--small{
    font-size: 14px;
    border: 0;
    padding: 5px 18px;
    height: 32px;
    span{
      height: 22px;
      display: inline-block;
      line-height: 22px;
      i{
        font-size: 14px;
        margin-right: 5px;
      }
    }
  }
  .el-button--default {
    @extend .font_2;
    @extend .bac_1;
  }
  .el-button--primary i{
    @extend .font_5;
  }
}
</style>
