<template>
  <tms-dialog
    ref="dialog"
    :title="$strings.change_pwd "
    width="639px"
    :before-close="close"
    @submit="submit"
    @cancel="close"
  >
    <el-form ref="form" label-position="right" :rules="rules" class=" form_input" :model="form" label-width="80px">
      <el-form-item :label="$strings.ori_pwd" prop="oldPassword">
        <el-input v-model.trim="form.oldPassword" v-emoji :type="oldpasswordType" :placeholder="$strings.please_input_place">
          <i slot="suffix" :class="`iconfont ${oldpasswordType?'icon-yincangmima':'icon-xianshimima'} font_2`" @click="showPassword('old')"></i>
        </el-input>
      </el-form-item>
      <el-form-item :label="$strings.new_pwd" prop="password">
        <el-input v-model.trim="form.password" v-emoji :type="currentpasswordType" :placeholder="$strings.please_input_place">
          <i slot="suffix" :class="`iconfont ${currentpasswordType?'icon-yincangmima':'icon-xianshimima'} font_2`" @click="showPassword('current')"></i>
        </el-input>
      </el-form-item>
      <el-form-item :label="$strings.repeat_pwd" prop="repeatPassword">
        <el-input v-model.trim="form.repeatPassword" v-emoji :type="repeatpasswordType" :placeholder="$strings.please_input_place">
          <i slot="suffix" :class="`iconfont ${repeatpasswordType?'icon-yincangmima':'icon-xianshimima'} font_2`" @click="showPassword('repeat')"></i>
        </el-input>
      </el-form-item>
    </el-form>
  </tms-dialog>
</template>
  
<script>
import { editInfo } from '@/api/admin/user'
import { aesEncrypt } from '@/util/encryption'
import { mapGetters } from 'vuex'


export default {
  name: 'DialogChangePwd',
  props: {
    refresh: {
      type: Function,
      default: () => {}
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
   
    return {
      visible: false,
      form: {
        password: '',
        repeatPassword: '',
        oldPassword: ''
      },
      rules: {
        password: [
          { required: true, message: this.$insertMsg(this.$strings.please_input, this.$strings.new_pwd), trigger: 'blur' },
          { validator: this.checkPassword, trigger: 'blur' }
        ],
        repeatPassword: [
          { required: true, message: this.$insertMsg(this.$strings.please_input, this.$strings.repeat_pwd), trigger: 'blur' },
          { validator: this.checkPassword, trigger: 'blur' }
        ],
        oldPassword: [
          { required: true, message: this.$insertMsg(this.$strings.please_input, this.$strings.ori_pwd), trigger: 'blur' }
       
        ]
      },
      oldpasswordType: 'password',
      currentpasswordType: 'password',
      repeatpasswordType: 'password'
    }
  },
  computed: {
    ...mapGetters(['website', "pwdRule"])
  },
  watch: {
    
  },
  
  created() {
    
  },
  mounted() {
   
  },
  methods: {
    show(row) {
      this.$refs.dialog.visible = true
    },
    close() {
      this.currentpasswordType = 'password'
      this.oldpasswordType = 'password'
      this.repeatpasswordType = "password"
      this.$refs.form.resetFields()
      this.$refs.dialog.visible = false
    },
    submit() {
      if (this.form.password !== this.form.repeatPassword) {
        this.$message.error(this.$strings.two_password_different)
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.edit()
        } 
      })
    },
    checkPassword(rule, value, callback) {
      const { ruleList, minLength, maxLength } = this.pwdRule
      const { regexString, passwoedTitle } = this.generatePasswordRegex(ruleList, minLength, maxLength)

      const chineseRegex = /[\u4e00-\u9fa5]/
      if (chineseRegex.test(value)) {
        callback(new Error(this.$strings.password_no_includes_desc))
      } else if (!regexString.test(value)) {
        callback(new Error(passwoedTitle))
      } else {
        callback()
      }
    },
    // 密码规则
    generatePasswordRegex(rules, minLength, maxLength) {
      const passwordArr = [
        {
          
          value: 1,
          rule: "(?=.*[A-Z])",
          strings: "password_upper_case"
          
        },
        {
         
          value: 2,
          rule: "(?=.*[a-z])",
          strings: "password_lower_case"
          
        },
        {
          
          value: 4,
          rule: "(?=.*\\d)",
          strings: "password_number"
          
        },
        {
          value: 8,
          rule: "(?=.*[-!$%^&*()_+|~=`{}\\[\\]:\";'<>?,./])",
          strings: "password_special_char"
        }
        
        
      ]
      const passwoedTitleArr = []
      let regexString = "^" // 开始匹配
      passwordArr.forEach(item => {
        if (rules.includes(item.value)) {
          regexString += item.rule
          passwoedTitleArr.push(this.$strings[item.strings])
          
        }
      })
      let passwoedTitle = passwoedTitleArr.join("、")
      regexString += ".{" + minLength + "," + maxLength + "}$" // 最小长度和最大长度
      passwoedTitle = this.$insertMsg(this.$strings.insert_password, minLength, maxLength, passwoedTitle)
      console.log(new RegExp(regexString))
      return {
        regexString: new RegExp(regexString),
        passwoedTitle
      }
    },
    encry(password) {
      return aesEncrypt(password, this.website.encPassword)
    },
    edit() {
      const { username, userId } = this.$store.state.user.userInfo
      const { password, oldPassword } = this.form
      editInfo({
        password: this.encry(oldPassword),
        username, userId,
        newpassword1: this.encry(password) 
      }).then(res => {
        this.$message.success(this.$strings.change_success)
        this.$store.dispatch("LogOut").then(() => {
          this.$router.push({ path: "/login" })
        })
      }).catch(error => {})
    },
    showPassword(str) {
      this[str + 'passwordType'] = this[str + 'passwordType'] === '' ? 'password' : ''
        
    }
  }
}
</script>
  <style lang="scss">
    
  </style>

