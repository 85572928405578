<template>
  <div class="just_between top_tag">
    <dialogChangePwd ref="dialog_change_pwd" /> 
    <div class="li_h_60 padd_l_r_24 font_s_14">
      <span v-if="parentTag.label&&(parentTag.label !==tag.label) " class="font_4 ">{{ $strings[parentTag.code] }}</span>
      <tms-icon v-if="parentTag.label&&(parentTag.label !==tag.label) " type="you" other-class="font_2 padd_l_r_4" />
      <span class="font_1 ">{{ $strings[tag.code] }}</span>
    </div>
    
    <div class="padd_l_r_4 m_t_14 dis_flex">
      <top-lanuage />
      <!-- <top-color /> -->
      <!-- <top-alarm /> -->
      <top-avactor :change-pwd="changePwd" />
    </div>
    <dialogEamil ref="dialog_email" />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { fullscreenToggel, listenfullscreen } from '@/util'
import topAvactor from './top-avactor'
import dialogChangePwd from './dialogChangePwd.vue'
import topLanuage from './top-lanuage.vue'
import dialogEamil from '@/pages/login/dialogEamil.vue'
import { ignore } from '@/api/admin/user'
import { setStore, getStore } from '@/util/store'
// import topColor from './top-color.vue'
import { getObj } from '@/api/admin/sys-public-param'

export default {
  name: 'Top',
  components: {
    topAvactor,
    dialogChangePwd,
    // topAlarm,
    topLanuage,
    dialogEamil
    // topColor
  },
  computed: {
    ...mapState({
      showDebug: state => state.common.showDebug,
      showTheme: state => state.common.showTheme,
      showLock: state => state.common.showLock,
      showFullScreen: state => state.common.showFullScreen,
      showCollapse: state => state.common.showCollapse,
      showMenu: state => state.common.showMenu,
      showColor: state => state.common.showColor
    }),
    ...mapGetters([
      'userInfo',
      'isFullScreen',
      'tagWel',
      'tagList',
      'isCollapse',
      'tag',
      'logsLen',
      'logsFlag',
      'tag',
      'parentTag',
      'keyCollapse',
      'emailFlag'
    ])
  },
  watch: {
    userInfo() {
      // handleImg(this.userInfo.avatar, 'thumbnail')
    }
  },
  created() {
    this.getOnlineTime()
    this.$store.dispatch('GetUserInfo').then(res => {
      const needChangeFlag = getStore({ name: 'needChangeFlag' })
      this.$nextTick(() => {
        if (res.nextChangePwdTime && (new Date(res.nextChangePwdTime) * 1) - new Date() * 1 <= 7 * 24 * 60 * 60 * 1000 && needChangeFlag) {
          setStore({
            name: 'needChangeFlag',
            content: false,
            type: 'session'
          })
          this.$tms_confirm(
            this.$insertMsg(this.$strings.pas_date, res.nextChangePwdTime),
            this.$strings.pas_title,
            {
              confirmButtonText: this.$strings.go_change,
              cancelButtonText: this.$strings.cancel,
              type: 'warning'
            }
          ).then(() => {
            this.changePwd()
            
          }).catch(() => {
            
          })
        }
        if (this.emailFlag && !res.sysUser.email) {
          this.$refs.dialog_email.show({})
        }
      })
    })
  },
  mounted() {
    listenfullscreen(this.setScreen)
  },
  methods: {
    handleScreen() {
      fullscreenToggel()
    },
    setCollapse() {
      this.$store.commit('SET_COLLAPSE', !this.keyCollapse)
    },
    setScreen() {
      this.$store.commit('SET_FULLSCREEN')
    },
    goRouter(path) {
      if (this.$route.path !== path && path) {
        this.$router.push({
          path: path
        })
      }
    },
    getOnlineTime() {
      getObj('ONLINE_RULE').then(res => {
        if (res.data.data) {
          this.$store.commit('SET_ONLINE_TIME', res.data.data)
        } else {
          this.$store.commit('SET_ONLINE_TIME', 24)
        }
        
      }).catch(error => {
        this.$store.commit('SET_ONLINE_TIME', 24)
      })
    },
    changePwd() {
      this.$refs.dialog_change_pwd && this.$refs.dialog_change_pwd.show()
    },
    changePwdConfirm() {
      this.$tms_confirm(
        this.$strings.change_pwd_sure,
        this.$strings.prompt,
        {
          confirmButtonText: this.$strings.submit,
          cancelButtonText: this.$strings.ignore,
          type: 'warning'
        }
      ).then(() => {
        this.changePwd()
      }).catch(() => {
        this.cancelConfirm()
      })
    },
    cancelConfirm() {
      ignore()
    }
  }
}
</script>

<style lang="scss" >
@import '@/styles/theme.scss';
.top_tag {
  padding: 0 !important;
  
  .popper__arrow{
    display: none !important;
  }
}
</style>
