<template>
  <el-dialog
    :title="computedTitle"
    :visible.sync="visible"
    :width="width"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="cancel"
    :center="center"
    :showClose="showClose"
    :append-to-body="appendToBody">
    <slot/>
    
    <div slot="title" v-if="changeTitle">
      <slot name="title" />
    </div>
    <div slot="footer" v-if="hasFooter">
        <el-button @click="cancel " v-if="cancelFlag">{{cancelTitle||$strings.cancel}}</el-button>
        <el-button type="primary" v-if="submitFlag" @click="submit($event)">{{ submitTitle||$strings.save }}</el-button>
    </div>
  </el-dialog>
</template>
  
<script>
let el;
export default {
  name: 'tmsDialog',
  props: {
    title: {
      type: String,
      default:''
    },
    width: {
      type: String,
      default: '30%'
    },
    beforeClose: {
      type: Function,
      default: () => {el.visible = false}
    },
    submitTitle: {
      type: String,
      default: ''
    },
    submitFlag: {
      type: Boolean,
      default:true
    },
    cancelFlag: {
      type: Boolean,
      default:true
    },
    cancelTitle: {
      type: String,
      default:''
    },
    appendToBody:{
      type: Boolean,
      default:true
    },
    showClose: {
      type: Boolean,
      default:true
    },
    center: {
      type: Boolean,
      default:false
    },
    hasFooter: {
      type: Boolean,
      default:true
    },
    changeTitle: {
      type: Boolean,
      default:false
    },
  },
  data() {
    return {
      visible: false,
      timeOut:null
    }
  },
  computed: {
    computedTitle() {

      if (this.title) {
        return this.title
      } else {
        return this.$strings.prompt
      }
      
    }
  },
  watch: {},
  created() {
    el = this
  },
  mounted() {
    let close_doms = window.document.getElementsByClassName('el-dialog__close')
    let close_func_out= function (e) {
      let dom = e.target
      dom.classList.remove('el-dialog__close_in')
      dom.classList.add('el-dialog__close_out')
    }
    let close_func_in= function (e) {
      let dom = e.target
      dom.classList.add('el-dialog__close_in')
      dom.classList.remove('el-dialog__close_out')
    }
    Array.from(close_doms).map(close_dom => {
      close_dom && close_dom.addEventListener("mouseout", close_func_out, true)
      close_dom && close_dom.addEventListener("mouseenter",close_func_in,true)
    })
  },
  beforeDestroy() {
    this.timeOut&&clearTimeout(this.timeOut)
  },
  methods: {
    cancel() {
      this.visible = false
      this.$emit('cancel')
    },
    submit(e) {
      // this.visible = false
      this.timeOut&&clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        this.$emit('submit',e)
      },1000)
      
    },
    show() {
      this.visible = true
    }
  }
}
</script>
  <style lang="scss" >
  @import '@/styles/theme.scss';
  .el-dialog{
    margin-top: 50vh !important;
    transform: translateY(-50%)
  }
  .el-dialog__header{
    @extend .bac_1;
    font-size: 20px;
    height: 60px;
    line-height: 60px;
    padding: 0 20px !important;
    border-bottom: #E5E6E8 1px solid;
  } 
  .el-dialog__body{
    padding: 20px !important;
  }
  .el-dialog__footer{
    height: 64px;
    border-top: #E5E6E8 1px solid; 
    padding: 16px 20px !important;
  }
  .el-dialog__close:hover{
    animation: close_rotate 0.2s ;
  }
  .el-dialog__close_in{
    animation: close_rotate 0.2s ;
  }
  .el-dialog__close_out{
    animation: close_rotate_back 0.2s ;
    
  }
  @keyframes close_rotate {
	10%{
    transform: rotate(20deg);
  }
  20%{
    transform: rotate(40deg);
  }
 30%{
    transform: rotate(60deg);
  }
  40%{
    transform: rotate(80deg);
  }
  50%{
    transform: rotate(100deg);
  }
  60%{
    transform: rotate(120deg);
  }
  70%{
    transform: rotate(140deg);
  }
  80%{
    transform: rotate(160deg);
  }
  90%{
    transform: rotate(180deg);
  }
	0%,
	100% {
		transform: translate(0, 0) rotate(0);
	}
  
  }
  @keyframes close_rotate_back {
    10%{
      transform: rotate(-20deg);
    }
    20%{
      transform: rotate(-40deg);
    }
  30%{
      transform: rotate(-60deg);
    }
    40%{
      transform: rotate(-80deg);
    }
    50%{
      transform: rotate(-100deg);
    }
    60%{
      transform: rotate(-120deg);
    }
    70%{
      transform: rotate(-140deg);
    }
    80%{
      transform: rotate(-160deg);
    }
    90%{
      transform: rotate(-180deg);
    }
    0%,
    100% {
      transform: translate(0, 0) rotate(0);
    }
    
  }
  </style>
