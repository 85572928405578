var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.menu
        ? _c("div", { staticClass: "avue-sidebar--tip" }, [
            _vm._v(_vm._s(_vm.$strings.menu_i18n_no)),
          ])
        : _vm._e(),
      _vm.menu
        ? _c(
            "el-menu",
            {
              directives: [
                {
                  name: "resize",
                  rawName: "v-resize",
                  value: _vm.resize,
                  expression: "resize",
                },
              ],
              ref: "menus",
              attrs: {
                "default-active": _vm.nowTagValue,
                "show-timeout": 200,
                collapse: _vm.keyCollapse,
                "default-openeds": _vm.nowOpens,
                "unique-opened": "",
                "collapse-transition": false,
                mode: "vertical",
              },
            },
            [
              _c("sidebar-item", {
                attrs: {
                  menu: _vm.menu,
                  screen: _vm.screen,
                  props: _vm.website.menu.props,
                  collapse: _vm.keyCollapse,
                  first: "",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }