var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout_home" },
    [
      _c("header", { staticClass: "layout_home_header" }, [
        _vm._v(_vm._s(_vm.$strings.quickly_entry)),
      ]),
      _c(
        "div",
        { staticClass: "layout_icon_wrap" },
        _vm._l(_vm.hotMenus, function (item) {
          return _c(
            "div",
            {
              key: item.menuId,
              staticClass: "icon_item",
              on: {
                click: function ($event) {
                  return _vm.goToRouter(item.path)
                },
              },
            },
            [
              _c("i", {
                class: `iconfont ${item.icon || "icon-shujuzhongxin2"}`,
              }),
              _c("br"),
              _c("span", [
                _vm._v(_vm._s(_vm.$strings[item.code] || item.name)),
              ]),
            ]
          )
        }),
        0
      ),
      _c("hr"),
      _c("header", { staticClass: "layout_home_header" }, [
        _vm._v(_vm._s(_vm.$strings.re_visit)),
      ]),
      _c(
        "div",
        { staticClass: "layout_icon_wrap" },
        _vm._l(_vm.lastMenus, function (item) {
          return _c(
            "div",
            {
              key: item.menuId,
              staticClass: "icon_item",
              on: {
                click: function ($event) {
                  return _vm.goToRouter(item.path)
                },
              },
            },
            [
              _c("i", {
                class: `iconfont ${item.icon || "icon-shujuzhongxin2"}`,
              }),
              _c("br"),
              _c("span", [
                _vm._v(_vm._s(_vm.$strings[item.code] || item.name)),
              ]),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "layout_user cur_po", on: { click: _vm.getManual } },
        [
          _c("div", { staticClass: "user_left" }, [
            _c("div", { staticClass: "project_name" }, [_vm._v("Angel Watch")]),
            _c("div", { staticClass: "project_message" }, [
              _vm._v(_vm._s(_vm.$strings.user_manual)),
            ]),
          ]),
          _c("div", { staticClass: "user_right" }, [
            _c("div", { staticClass: "project_message" }, [
              _vm._v(_vm._s(_vm.$strings.user_manual)),
            ]),
          ]),
        ]
      ),
      _c("header", { staticClass: "layout_home_header just_between" }, [
        _c("span", [_vm._v(_vm._s(_vm.$strings.pda_active))]),
        _c(
          "span",
          {
            staticClass: "font_primary cur_po font_s_14",
            on: { click: _vm.goToDetail },
          },
          [_vm._v(_vm._s(_vm.$strings.get_more))]
        ),
      ]),
      _c(
        "div",
        { staticClass: "pda_wrap" },
        _vm._l(_vm.list, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "pda_item",
              on: {
                click: function ($event) {
                  return _vm.gotoQuil(item.id)
                },
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.type === 1,
                      expression: "item.type===1",
                    },
                  ],
                  staticClass: "message_pda",
                },
                [_vm._v(_vm._s(_vm.$strings.type_info))]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.type === 2,
                      expression: "item.type===2",
                    },
                  ],
                  staticClass: "active_pda",
                },
                [_vm._v(_vm._s(_vm.$strings.type_active))]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.type === 3,
                      expression: "item.type===3",
                    },
                  ],
                  staticClass: "title_pda",
                },
                [_vm._v(_vm._s(_vm.$strings.type_message))]
              ),
              _c("div", [_vm._v(_vm._s(item.title))]),
            ]
          )
        }),
        0
      ),
      _c("DialogDetail", {
        ref: "dialog_detail",
        attrs: {
          title: _vm.$strings.info_list,
          "fetch-detail": _vm.fetchDetail,
        },
      }),
      _c("DialogQuil", { ref: "dialog_quil" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }