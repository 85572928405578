<template>
    <remote-js :src="url" @loaded="callbackFunc"/>
</template>
  
  <script>

  export default {
    components: {
      'remote-js': {
        render(createElement) {
          var self = this
          var hasCurrentSrc = false
          var srciptsArr = document.getElementsByTagName('script')
          for (let index = 0; index < srciptsArr.length; index++) {
            hasCurrentSrc = srciptsArr[index].src===this.src
          }
          if (!hasCurrentSrc) {
            return createElement('script', {
            attrs: { type: 'text/javascript', src: this.src },
            on: {
              load: function() {
                self.$emit('loaded')
              }
            }
          })
          }
          
        },
        props: {
          src: { type: String, required: true }
        }
      }
  },
    props: {
      url: { type: String, required: true }, // 需要加载的外部url
      callbackFunc: {type:Function,default:()=>{}}// 外部js加载完成回调
    }
  }
  </script>
