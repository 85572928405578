import Vue from 'vue'
import VueRouter from 'vue-router'
import PageRouter from './page/'
import ViewsRouter from './views/'
import RouterPlugin from './routerplugin'
import Store from '../store/'
Vue.use(VueRouter)
//创建路由
export const createRouter = () => new VueRouter({
  routes: [...PageRouter, ...ViewsRouter]
})

const Router = createRouter()
RouterPlugin.install(Router, Store)
Router.$avueRouter.formatRoutes(Store.state.user.menu, true)
// 重置路由
export function resetRouter() {
  const newRouter = createRouter()
  Router.matcher = newRouter.matcher

}
export default Router
