<template>
  <div id="app" :style="{'--width':width,'--height':height, }">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  data() {
    return {
      width: 1920 / 1440,
      height: 1080
    }
  },
  computed: {
    ...mapGetters([
      'keyCollapse',
      'collFlag',
      'webConfig'
    ])
  },
  
  watch: {},
  beforeCreate() {
  },
  created() {
    this.width = window.screen.width / 1440
    this.height = window.screen.height
  },
  mounted() {
    if (window.innerWidth < 1200) {
      this.$store.commit('SET_COLLAPSE', true)
    }
    if (window.innerWidth > 1200) {
      this.$store.commit('SET_COLLAPSE', false)
    }
    window.onresize = () => {
      this.getInner()
      window.echartResize && window.echartResize()
    }
  },
  methods: {
    getInner() {
      if (window.innerWidth < 1200 && !this.collFlag) {
        this.$store.commit('SET_COLLAPSE', true)
      }
      if (window.innerWidth > 1200 && !this.collFlag) {
        this.$store.commit('SET_COLLAPSE', false)
      }
      this.$store.commit('SET_INNERWIDTH', window.innerWidth)
      this.$store.commit('SET_INNERHEIGHT', window.innerHeight)
    }
  }
}
</script>
<style lang="scss">
  #app {
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-family: sans-serif !important;
  }
</style>
