<template>
  <tms-dialog
    ref="dialog"
    :title="title"
    width="960px"
    :before-close="close"
    :submit-flag="false"
    :cancel-title="$strings.close"
    :append-to-body="true"
    @cancel="close"

  >
      <div class="ql-container ql-snow">
        <div class="ql-editor " v-html="content">
        </div>
      </div>
  </tms-dialog>
</template>
  
<script>
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import tmsIconList from '../iconList'
import {getObj} from '@/api/admin/cement'
export default {
  name: "Dialog",
  props: {
    
  },
  comments: {
    tmsIconList
  },
  data() {
    return {
      title:this.$strings.detail,
      content:'',
      id:''
    }
  },
  methods: {
    show(id) {
      this.$refs.dialog.visible = true
      this.id = id
      this.getDetail()
    },
    close() {
      this.content=''
      this.$refs.dialog.visible = false
      
    },
    getDetail() {
      getObj(this.id).then(res => {
        this.content = res.data.data.content
      }).catch(error=>{})
    }
    
  }
}
</script>
<style lang="scss">
.ql-editor{
  height: 500px;
  iframe{
    width: 100%;
    height:400px;
  }
}
</style>
