<template>
  <div v-if="currentList.length&&currentList.length<=iconLength" class="tms_icon_list">
    <icon 
    v-for="(item,index) in currentList" 
    :key="item.type" 
    :type="item.type" 
    :other-class="`${currentClass} font_s_16 ${index===currentList.length-1?'':'m_r_15'} font_primary`"
    :title="item.title" :func="item.event" />
  </div>
  <div v-else-if="currentList.length&& currentList.length>iconLength" class="tms_icon_list">
    
    <icon 
    v-for="(item,index) in beforeList" 
    :key="item.type" 
    :other-class='` ${currentClass} font_s_16 m_r_15 font_primary`'
    :type="item.type" 
    :title="item.title" :func="item.event" />

    <el-dropdown>
      <icon  type="gengduo" :other-class='`${currentClass} font_s_16 font_primary`'  />
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="(item,index) in afterList"  :key="item.type" :class="item.type.includes('danger_color')? 'bac_danger':''" >
          <div @click="item.event" :class="item.type.includes('danger_color')?'danger_color ':''">
             <icon 
                :other-class='` font_s_16 font_primary`'
                :type="item.type" 
              />
              {{ item.title }}
          </div>
       
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
  
<script>
import { deepClone } from '@/util'
import icon from '../icon/index.vue'
let el;
export default {
  name: 'tmsIconList',
  components: {
    icon
  },
  props: {
    iconList: {
      type: Array,
      default: () => []
      //[ {title:'',type:'',flag:false},@click]
    },
    currentClass: {
      type: String,
      default:''
    },
    iconLength: {
      type: Number,
      default:3
    }
  },
  data() {
    return {
      visible: false,
      currentList: [],
      beforeList: [],
      afterList:[]
    }
  },
  computed: {
  },
  watch: {
    iconList: {
      handler (iconList) {
        this.currentList = iconList.filter(item => item.flag)
        if (this.currentList.length > this.iconLength) {
          let newList = deepClone(this.currentList)
          this.beforeList = newList.splice(0, this.iconLength-1)
          this.afterList = newList
        }
      },
      deep:true
    }
  },
  created() {
    el = this
    this.currentList = this.iconList.filter(item => item.flag)
    if (this.currentList.length > this.iconLength) {
        let newList = deepClone(this.currentList)
        this.beforeList = newList.splice(0, this.iconLength-1)
        this.afterList = newList
      }
  },
  methods: {
    
  }
}
</script>
  <style lang="scss" >
 .tms_icon_list{
  max-height: 22px;
 }
  </style>
