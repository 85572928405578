import request from '@/router/axios'

export function reportMenu(id) {
  return request({
    url: '/admin/userMenuRank/reportMenu/' + id,
    method: 'get'
  })
}

export function getLastMenus() {
  return request({
    url: '/admin/userMenuRank/getLatestMenuList',
    method: 'get'
  })
}


export function getHotMenus() {
  return request({
    url: '/admin/userMenuRank/getHotMenuList',
    method: 'get'
  })
}

