
import Vue, { nextTick } from 'vue'
import website from '@/const/website'
import { getStore, setStore } from '@/util/store.js'
import { getLangAccept } from '@/util/language'
import { rewriteElTooltip, rewriteElMs } from './util'
import locale from 'element-ui/lib/locale'
import locale_zh from 'element-ui/lib/locale/lang/zh-CN' 
import locale_en from 'element-ui/lib/locale/lang/en' 
import * as base from '@/language/index'
import {
  Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Descriptions,
  DescriptionsItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  TimePicker,
  Popover,
  Tooltip,
  Form,
  FormItem,
  Loading,
  MessageBox,
  Tabs,
  TabPane,
  Tag,
  Tree,
  Icon,
  Row,
  Col,
  Upload,
  Progress,
  Badge,
  Card,
  Cascader,
  Link,
  Message,
  Notification
} from 'element-ui'
import jquery from 'jquery'
// const s3 = new AWS.S3()
window.getStore = getStore
window.setStore = setStore
const el_loacl = locale

export default async function getLanguage(e, callback) {
  e = getStore({ name: 'langCode' }) || e
  const locale = e === 'zh_CN' ? locale_zh : locale_en 
  let flag
  // if (e === 'zh_CN') {
  //   insertAmap()
  // } else {
  //   insertGmap()
  // }
  Vue.use(Descriptions)
  Vue.use(DescriptionsItem)
  Vue.use(Pagination)
  Vue.use(Dialog)
  Vue.use(Autocomplete)
  Vue.use(Dropdown)
  Vue.use(DropdownMenu)
  Vue.use(DropdownItem)
  Vue.use(Menu)
  Vue.use(Submenu)
  Vue.use(MenuItem)
  Vue.use(MenuItemGroup)
  Vue.use(Input)
  Vue.use(InputNumber)
  Vue.use(Radio)
  Vue.use(RadioGroup)
  Vue.use(RadioButton)
  Vue.use(Checkbox)
  Vue.use(CheckboxButton)
  Vue.use(CheckboxGroup)
  Vue.use(Switch)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(OptionGroup)
  Vue.use(Button)
  Vue.use(ButtonGroup)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(DatePicker)
  Vue.use(TimeSelect)
  Vue.use(TimePicker)
  Vue.use(Popover)
  Vue.use(Tooltip)
  Vue.use(Form)
  Vue.use(FormItem)
  Vue.use(Tabs)
  Vue.use(TabPane)
  Vue.use(Tag)
  Vue.use(Tree)
  Vue.use(Icon)
  Vue.use(Row)
  Vue.use(Col)
  Vue.use(Upload)
  Vue.use(Progress)
  Vue.use(Badge)
  Vue.use(Card)
  Vue.use(Cascader)
  Vue.use(Link)
  Vue.use(Loading.directive)
  Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000, locale }
  el_loacl.use(locale)
  const el_t = Tooltip
  const mes_b = MessageBox
  rewriteElTooltip(el_t)
  rewriteElMs(mes_b, Vue, nextTick)
  Vue.prototype.$loading = Loading.service
  Vue.prototype.$msgbox = MessageBox
  Vue.prototype.$alert = MessageBox.alert
  Vue.prototype.$confirm = MessageBox.confirm
  Vue.prototype.$prompt = MessageBox.prompt
  Vue.prototype.$notify = Notification
  Vue.prototype.$message = Message
 
  const accept = getLangAccept(e)
  let langObj = {}
  const LanguageType = process.env.VUE_APP_LANGUAGE_ENV || 'uat'
  const base_obj = {
    "Bucket": "uitms",
    "Key": `tms/tms-ui/language/${LanguageType}/${e}/${website.language.base}.json`,
    "version": null
  }
  const current_obj = {
    "Bucket": "uitms",
    "Key": `tms/tms-ui/language/${LanguageType}/${e}/${website.language.current}.json`,
    "version": null
  }
  if (process.env.VUE_APP_LANGUAGE_ENV === 'uat') {
    await postLang(base_obj).then(res => {
      res && Object.assign(langObj, res)
    }).catch(error => {
      flag = true
      callback()
    })
    await postLang(current_obj).then(res => {
      res && Object.assign(langObj, res)
    }).catch(error => {
      flag = true
      callback()
    })
  } else {
    Object.assign(langObj, base[e])
  }
 
  if (flag) return
  if (JSON.stringify(langObj) === "{}" && getStore({ name: 'langMap' })) {
    langObj = getStore({ name: 'langMap' })
  }
  setStore({
    name: 'langMap',
    content: langObj
  })
  setStore({
    name: 'accept',
    content: accept
  })
  Vue.prototype.$strings = langObj
  callback()
}
function postLang(params) {
  return new Promise((resolve, reject) => {
    jquery.ajax({
      url: 'https://uitmss3.eu.aw-iot.com/' + params.Key,
      headers: {
        "Content-Type": 'application/json',
        "Cache-control": 'no-cache'
      },
      type: 'GET',
      dataType: 'json',
      timeout: 30000,
      cors: true,
      success: function(a, b) {
        resolve(a)
      },
      error: function(a, b) {
        reject(a)
      }
    })
    // s3.getObject(params, function(err, data) {
    //   if (data) {
    //     const objectData = JSON.parse(data.Body.toString('utf-8')) // Use the encoding necessary/
    //     resolve(objectData)
    //   } else {
    //     return null
    //   }
      
    // })
  })
}


