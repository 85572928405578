<template>
  <div class="layout">
    <div class="layout_left flex_col " :style="{'min-width':keyCollapse?'64px':'247px' }">
      <div v-if="keyCollapse" class="hei_60 font_1  li_h_60 font_w_500 font_s_20 cur_po" @click="goToHome">
        <img :src="webConfig.closingMenuBar" class="img_logo" />
      </div>
      <div v-if="!keyCollapse" class="hei_60 font_1  li_h_60 font_w_500 font_s_20 cur_po" @click="goToHome">
        <img :src="webConfig.expendingMenuBar" class="img_all_logo" />
      </div>
      <div class=" over_y_auto over_x_hid  m_r_2" :style="{ 'height': innerHeight-100+'px'}">
        <Sidebar ref="menu" />
      </div>
      <div :class="`flex_1 m_t_12  ${keyCollapse?'tac':'m_r_20 tar'}`">
        <tms-icon :type="keyCollapse?'zhankai1':'shouqi'" other-class="" :func="changeCollapse"></tms-icon>
      </div>
    </div>
    <div class="layout_right flex_col" :style="{ 'min-width': rightWidth+'px'}">
      <div class="hei_60 bac_w border_3 border_b_1_s" :style="{ 'width': '100%'}">
        <Top />
      </div>
      <div class="flex_1  padd_b_0 flex_col wid_100 " :style="{ 'overflow':'auto'}">
        <div class="flex_1">
          <keep-alive>
            <router-view
              v-if="$route.meta.keepAlive" />
          </keep-alive>
          <router-view
            v-if="!$route.meta.keepAlive" />
        </div>
        
      </div>
      <!-- <Bottom /> -->
    </div>
  </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import Sidebar from './sidebar/index.vue'
import { checkToken } from '@/api/login'
import Top from './top/index.vue'
import color from '@/mixins/color'
import { getTenantDetail } from '@/api/admin/tenant'
import OriWebsite from '@/const/website'
export default {
  
  name: 'Index',
  components: {
    Sidebar,
    Top
    // Bottom
  },
  mixins: [color()],
  provide() {
    return {
      Index: this
    }
  },
  data() {
    return {
      chalk: '#165DFF',
      refreshTime: ''
    }
  },
  computed: {
    ...mapGetters([
      'website',
      'isCollapse',
      'keyCollapse',
      'innerWidth',
      'innerHeight',
      'theme',
      'webConfig',
      'userInfo'
    ]),
    rightWidth() {
      let width = this.innerWidth
      width -= this.keyCollapse ? 64 : 247
      return width
    }
  },
  metaInfo() {
    return {
      title: this.webConfig.webTitle,
      link: [
        {
          rel: 'icon',
          href: this.webConfig.titleIcon, // 替换为实际图标文件的路径
          type: 'image/x-icon'
        }
      ]
    }
    
  },
  watch: {},
  destroyed() {
    clearInterval(this.refreshTime)
  },
  created() {
    this.getConfigDetail(this.userInfo.tenantId)
    this.$store.commit('SET_THEME', this.theme)
    this.updateTheme(this.theme, '#165DFF')
    this.refreshToken()

  },
  mounted() {
    
    window.onbeforeunload = () => {
      //默认色修改
      this.$store.commit('SET_THEME', this.theme)
      this.updateTheme(this.theme, '#165DFF')
    }
    window.onunload = () => {
      //默认色修改
      this.$store.commit('SET_THEME', this.theme)
      this.updateTheme(this.theme, '#165DFF')
    }

  },
  methods: {
    openMenu(item = {}) {
      this.$store.dispatch('GetMenu', { type: true, id: item.id }).then(data => {
        if (data.length !== 0) {
          this.$router.$avueRouter.formatRoutes(data, true)
        }
      }).catch(error => {})
    },
    changeCollapse() {
      this.$store.commit('SET_COLLAPSE_FLAG', true)
      this.$store.commit('SET_COLLAPSE', !this.keyCollapse)
    },
    //跳转home页面
    goToHome() {
      if (this.$route.path !== '/index') {
        this.$router.push({
          path: '/index'
        })
      }
      
    },
    getConfigDetail(tenantId) {
      
      getTenantDetail({ tenantId }).then(res => {
        if (res.data.data) {
          this.changeStore(res.data.data)
        } else {
          this.changeStore({
            "homeLogo": "",
            "icon": "",
            "loginBoxLogo": "",
            "background": "",
            "icpFilingNumber": "",
            "webTitle": "",
            "titleIcon": "",
            "expendingMenuBar": "",
            "closingMenuBar": ""
          })
        }
      })
    },
    changeStore(config) {
      const newObj = {}
      
      const newWebSit = JSON.parse(JSON.stringify(OriWebsite))
      const OldconfigKyes = Object.keys(newWebSit) 
      for (const key in config) {
        const value = config[key]
        if (value) {
          newObj[key] = value
        } else {
          newObj[key] = newWebSit[key]
        }
      }
      newObj.flag = OldconfigKyes.every(item => !config[item]) 
      this.$store.commit('SET_WEBCONFIG', newObj)
    },
    // 实时检测刷新token
    refreshToken() {
      this.refreshTime = setInterval(() => {
        checkToken(this.refreshTime, this.refreshLock, this.$store)
      }, 60000)
    }
  }
}
</script>
  <style lang="scss">
    .img_all_logo{
      width: 200px;
      height: 50px;
      margin: 5px 10px;
    }
    .img_logo{
      width: 40px;
      height: 40px;
      margin: 5px 13px;
    }
  </style>
