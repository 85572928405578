var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentList.length && _vm.currentList.length <= _vm.iconLength
    ? _c(
        "div",
        { staticClass: "tms_icon_list" },
        _vm._l(_vm.currentList, function (item, index) {
          return _c("icon", {
            key: item.type,
            attrs: {
              type: item.type,
              "other-class": `${_vm.currentClass} font_s_16 ${
                index === _vm.currentList.length - 1 ? "" : "m_r_15"
              } font_primary`,
              title: item.title,
              func: item.event,
            },
          })
        }),
        1
      )
    : _vm.currentList.length && _vm.currentList.length > _vm.iconLength
    ? _c(
        "div",
        { staticClass: "tms_icon_list" },
        [
          _vm._l(_vm.beforeList, function (item, index) {
            return _c("icon", {
              key: item.type,
              attrs: {
                "other-class": ` ${_vm.currentClass} font_s_16 m_r_15 font_primary`,
                type: item.type,
                title: item.title,
                func: item.event,
              },
            })
          }),
          _c(
            "el-dropdown",
            [
              _c("icon", {
                attrs: {
                  type: "gengduo",
                  "other-class": `${_vm.currentClass} font_s_16 font_primary`,
                },
              }),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                _vm._l(_vm.afterList, function (item, index) {
                  return _c(
                    "el-dropdown-item",
                    {
                      key: item.type,
                      class: item.type.includes("danger_color")
                        ? "bac_danger"
                        : "",
                    },
                    [
                      _c(
                        "div",
                        {
                          class: item.type.includes("danger_color")
                            ? "danger_color "
                            : "",
                          on: { click: item.event },
                        },
                        [
                          _c("icon", {
                            attrs: {
                              "other-class": ` font_s_16 font_primary`,
                              type: item.type,
                            },
                          }),
                          _vm._v(" " + _vm._s(item.title) + " "),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }