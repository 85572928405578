var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "just_between top_tag" },
    [
      _c("dialogChangePwd", { ref: "dialog_change_pwd" }),
      _c(
        "div",
        { staticClass: "li_h_60 padd_l_r_24 font_s_14" },
        [
          _vm.parentTag.label && _vm.parentTag.label !== _vm.tag.label
            ? _c("span", { staticClass: "font_4" }, [
                _vm._v(_vm._s(_vm.$strings[_vm.parentTag.code])),
              ])
            : _vm._e(),
          _vm.parentTag.label && _vm.parentTag.label !== _vm.tag.label
            ? _c("tms-icon", {
                attrs: { type: "you", "other-class": "font_2 padd_l_r_4" },
              })
            : _vm._e(),
          _c("span", { staticClass: "font_1" }, [
            _vm._v(_vm._s(_vm.$strings[_vm.tag.code])),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "padd_l_r_4 m_t_14 dis_flex" },
        [
          _c("top-lanuage"),
          _c("top-avactor", { attrs: { "change-pwd": _vm.changePwd } }),
        ],
        1
      ),
      _c("dialogEamil", { ref: "dialog_email" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }