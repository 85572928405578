import request from '@/router/axios'

export function getMenu(id) {
  return request({
    url: '/admin/bizMenu',
    params: { parentId: id },
    method: 'get'
  })
}

export function getTopMenu() {
  return request({
    url: '/admin/bizMenu',
    params: { type: 'top' },
    method: 'get'
  })
}

export function fetchMenuTree(params) {
  return request({
    url: '/admin/bizMenu/tree',
    method: 'get',
    params: params
  })
}
export function permissionUpd(deptId, menuIds) {
  return request({
    url: '/admin/bizMenu/updateByDeptId',
    method: 'put',
    data: {
      deptId: deptId,
      menuIds: menuIds
    }
  })
}
export function fetchRoleTree(deptId) {
  return request({
    url: '/admin/bizMenu/getByDeptId/' + deptId,
    method: 'get'
  })
}
export function addObj(obj) {
  return request({
    url: '/admin/bizMenu',
    method: 'post',
    data: obj
  })
}

export function getObj(id) {
  return request({
    url: '/admin/bizMenu/' + id,
    method: 'get'
  })
}

export function delObj(id) {
  return request({
    url: '/admin/bizMenu/' + id,
    method: 'delete'
  })
}

export function putObj(obj) {
  return request({
    url: '/admin/bizMenu',
    method: 'put',
    data: obj
  })
}
