const resize = {
  bind(el, binding, vnode, oldVnode) {
 
    let width = ''
    function isReize() {
      const style = document.defaultView.getComputedStyle(el)
      if (width !== style.width) {
        binding.value(style.width.split('px')[0]) // 关键
      }
      width = style.width
    }
    el.__vueSetInterval__ = setInterval(isReize, 300)
  },
  update(el, binding, vnode, oldVnode) {}
}
export default resize
