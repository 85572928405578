import Layout from '@/pages/layout/'
export default [
  {
    path: '/login',
    name: '登录页',
    component: () =>
      import(/* webpackChunkName: "page" */ '@/pages/login/index'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false,
      code: 'menu_i18n_login'
    }
  },
  {
    path: '/ping',
    name: 'ping',
    component: () =>
      import(/* webpackChunkName: "page" */ '@/pages/layout/Ping'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false,
      code: 'menu_i18n_login'
    }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [{
      path: 'index',
      name: '首页',
      meta: {
        keepAlive: false,
        isAuth: true,
        code: 'menu_i18n_index'
      },
      component: () =>
        import(/* webpackChunkName: "views" */ '@/pages/layout/Home')
    }]
  }
  
]
