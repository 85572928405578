<template>
  <div :id="mapId" class="map_wrap">
    <!-- <el-select v-model="mapType" class="l_control">
      <el-option  v-for="(item) in mapList"  :key="item.value" :label="item.name" :value="item.value"></el-option>
    </el-select> -->
    <div class="map_footer_right_bottom wid_100"><slot name="footer" ></slot></div>
    <slot/>
  </div>
</template>

<script>
import { initChinaProvider } from './china'
import { mapGetters } from 'vuex'
import './editable'
import { getStore, setStore } from '@/util/store.js'
import {  mathAll } from '@/util/util.js'
import gcoord from 'gcoord'

function insertAmap(key) {
  const scr_dom = document.createElement('script')
  scr_dom.type = "text/javascript"
  scr_dom.src = `https://webapi.amap.com/maps?v=2.0&key=${key || 'de4d9f99cee4befcf0fb29b517f9006e'}`
  document.getElementsByTagName('head')[0].appendChild(scr_dom)
}
function insertGmap(key) {
  const scr_dom = document.createElement('script')
  scr_dom.type = "text/javascript"
  scr_dom.src = `https://maps.googleapis.com/maps/api/js?key=${key || 'AIzaSyBpZj0ckdMN3Qttn5p-dNlKnaP53ftpLhI'}&libraries=places`
  document.getElementsByTagName('head')[0].appendChild(scr_dom)
}

export default {
  name: 'ExcelUpload',
  components: {
  },
  props: {
    mapId: {
      type: String,
      default:'map_1'
    },
    getCircle: {
      type: Function,
      default:()=>{}
    },
    getPolygon : {
      type: Function,
      default:()=>{}
    },
    setPoint : {
      type: Function,
      default:()=>{}
    }
  },
  data() {
    return {
      point:getStore({name:'point'})|| {
            lng:  0,
            lat:0
          },
      mapLayers:{},
      minZoom: 5,
      maxZoom: 18,
      mapList: [
        {
          name: '高德地图',
          value:'amap'
        },
        {
          name: 'OSM地图',
          value:'osm'
        }
      ],
      mapType: getStore({ name: 'langCode' }) === 'zh_CN' ? 'amap' : 'osm',
      canDrawCircle: false,
      circleObj: {},
      customPolygonObj:{},
      fenceList:[]
    }
  },
  watch: {
    mapType: function (val) {
      if (val) {
        let index = this.getIndex(val)
        let layersSelector = document.getElementsByClassName('leaflet-control-layers-selector');
        if (layersSelector.length) {
          layersSelector[index].click()
        this.$store.commit('SET_MAPTYPE',val)
        }
        
      }
      
    },
    theme: function (val) {
      if (val&&this.leafletMap) {
        let center = this.circleObj.getLatLng()
        let radius = parseInt(this.circleObj.getRadius())
        this.addCircle(center,radius)
      }
    }
  },
  computed: {
    ...mapGetters(['theme'])
  },
  created() {
    if (getStore({ name: 'langCode' }) === 'zh_CN') {
      insertAmap()
    } else {
      insertGmap()
    }
    this.getCurrent()
    this.initMapLayers()
  },
  beforeDestroy() {
    
  },
  mounted() {
    this.initMap()
    document.getElementsByClassName('map_footer_right_bottom')[0].addEventListener('click', (e) => {
      e.stopPropagation()
      e.preventDefault()
    })
  },
  methods: {
    //经纬度解析
     getLatLng(lat, lng) {
      return L.latLng({ lat, lng })
    },
    autoCrs(point,target,current) {
      if (!point.lng || !point.lat) return 
      let result = gcoord.transform([point.lng, point.lat], gcoord[target], gcoord(current))
      return {
        lng: result[0],
        lat:result[1]
      }
    },
    /**
     * 添加zoom控件
     */
    addZoomControl() {
      let options = {
        position: 'bottomright',
      };
      let control = L.control.zoom(options);
      this.leafletMap.addControl(control);
    },
       /**
     * 添加scale控件
     */
    addScaleControl() {
      const scale = L.control.scale({ metric: true, imperial: false });
        //将比例尺控件加载到地图容器中
      this.leafletMap.addControl(scale)
    },
    //init 地图
    initMap() {
        this.leafletMap=L.map(this.mapId, {
          crs:  L.CRS.EPSG3857,
          minZoom: 5,
          maxZoom: 18,
          attributionControl: false,
          center:this.point.lat? [this.point.lat, this.point.lng]:[34.45, 108.85],
          zoom: 5,
          layers:this.mapLayers[this.mapType] ,
          zoomControl: false,
          editable: true,
        });
        this.$store.commit('SET_MAPTYPE',this.mapType)
        this.addMapTypeControl()
        this.addZoomControl()
        this.addScaleControl()
    },
    //获取当前定位
    getCurrent() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const latitude = position.coords.latitude; // 纬度
          const longitude = position.coords.longitude; // 经度
          let point = {
            lng: longitude,
            lat:latitude
          }
          setStore({
            name: 'point',
            content: point
          })
          //获取当前定位后init地图(取浏览器定位)
        }, () => {
           //获取当前定位后失败init地图(取默认定位)
        })
      }
    },
     /**
     * 初始化地图层，存起来以备后面切换使用
     */
    initMapLayers() {
      initChinaProvider()
      var GaoDe_Imgm = L.tileLayer.chinaProvider('GaoDe.Satellite.Map', {
        maxZoom: this.maxZoom,
        minZoom: this.minZoom
      });
      var GaoDe_Imga = L.tileLayer.chinaProvider('GaoDe.Satellite.Annotion', {
        maxZoom: this.maxZoom,
        minZoom: this.minZoom
      });
      var GaoDe_RealTime = L.tileLayer.chinaProvider('GaoDe.RealTime.Map', {
        maxZoom: this.maxZoom,
        minZoom: this.minZoom,
      });
      var amap = L.tileLayer.chinaProvider('GaoDe.Normal.Map', {
        maxZoom: this.maxZoom,
        minZoom: this.minZoom
      });
      var osm = L.tileLayer.chinaProvider('OSM.Normal.Map', {
        maxZoom: this.maxZoom,
        minZoom: this.minZoom
      });
      var GaoDe_SatelliteleafletMap = L.layerGroup([GaoDe_Imgm, GaoDe_Imga]);
      var GaoDe_RealTimeleafletMap = L.layerGroup([amap, GaoDe_RealTime]);
      var GaoDe_Satellite_RealTimeleafletMap = L.layerGroup([GaoDe_Imgm, GaoDe_Imga, GaoDe_RealTime]);
      this.mapLayers = {
        amap,
        GaoDe_SatelliteleafletMap,
        GaoDe_RealTimeleafletMap,
        GaoDe_Satellite_RealTimeleafletMap,
        osm
      }
      
    },
    //添加地图图层切换
    addMapTypeControl() {
      let baseLayers = {}
      this.mapList.forEach((item) => {
        baseLayers[item.name] =this.mapLayers[item.value] 
      })
      L.control.layers(baseLayers).addTo(this.leafletMap) 
      
    },
    //获取layersSelect下标
    getIndex(val) {
      let type_index;
      this.mapList.forEach((item,index) => {
        if (item.value === val) {
          type_index=index
        }
      })
      return type_index
    },
    //添加圆形围栏
    addCircle(point, meter, enable = true) {
      new Promise( (resolve) =>{
        this.clearAllFences();
        this.leafletMap.off('editable:drawing:move', this.handleFenceCircleDarwingEvent);
        this.leafletMap.off('click', this.handleMapClick)
        resolve()
      }).then( () =>{
        this.canDrawCircle = true
        this.circleObj = L.circle(point, {
          weight: 4,
          radius: meter ? meter : 200,
          fillColor: this.theme,
          fillOpacity: 0.2,
          color: this.theme
        }).addTo(this.leafletMap);
        //zoom设置
        this.setZoomByMeter(meter || 200)
         //中心点视野设置
        this.setCenter(point)
        if (this.getCircle) {
          this.getCircle({
            ...point,
            radius:meter||200
          })
        }
        enable&&this.circleObj.enableEdit()
        this.leafletMap.on('editable:drawing:move', this.handleFenceCircleDarwingEvent);
        this.leafletMap.on('click', this.handleMapClick)
        this.fenceList.push(this.circleObj)
      })
    },
    /**
     * 添加多边形围栏
     * @param point
    */
    addCustomPolygon( point) {
      new Promise( (resolve) =>{
        this.clearAllFences();
        this.leafletMap.off('editable:drawing:move', this.handleFenceCircleDarwingEvent);
        this.leafletMap.off('click', this.handleMapClick)
        resolve()
      }).then( () =>{
        let center = [mathAll(point.lat, 0.002, 'subtract') , mathAll(point.lng, 0.002, 'add')];
        const polygons = []
        polygons.push(center)
        polygons.push([center[0],mathAll(center[1],0.01,'subtract')])
        polygons.push([mathAll(center[0], 0.008, 'add'), center[1]])
        let polygon = L.polygon(polygons, {
          weight: 2,
          radius: 200,
          fillColor: this.theme,
          fillOpacity: 0.2,
          color: this.theme
        }).addTo(this.leafletMap);
        polygon.enableEdit()
        this.setCenter(point, 16);//让地图平滑移动至新中心点
        if (this.getPolygon) {
          const ourNeedPolygons = polygons.map(item => {
            return {
              lat: item[0],
              lng:item[1]
            }
          })
          this.getPolygon(ourNeedPolygons)
        }
        this.fenceList.push(polygon);
        this.customPolygonObj = {
           polygon
        }
        this.leafletMap.on('editable:drawing:move', this.handleCustomPolygonEnd);
      })
    },
    //edit polygon fence
    editCustomPolygon( points) {
      new Promise( (resolve) =>{
        this.clearAllFences();
        this.leafletMap.off('editable:drawing:move', this.handleFenceCircleDarwingEvent);
        this.leafletMap.off('click', this.handleMapClick)
        resolve()
      }).then( () =>{
        let polygon = L.polygon(points, {
          weight: 2,
          radius: 200,
          fillColor: this.theme,
          fillOpacity: 0.2,
          color: this.theme
        }).addTo(this.leafletMap);
        polygon.enableEdit()
        //设置多边形中心点
        if (this.setPoint) {
          this.setPoint(polygon.getCenter())
        }
        this.setBestView(points);//让地图平滑移动至新中心点
       
        this.fenceList.push(polygon);
        this.customPolygonObj = {
           polygon
        }
        this.leafletMap.on('editable:drawing:move', this.handleCustomPolygonEnd);
      })
    },
    /**
     * 自定义多边形围栏 画图结束处理
     * @param e
     */
    handleCustomPolygonEnd(e) {
      const { polygon } = this.customPolygonObj;
      let polygons = polygon.getLatLngs()[0];
      if (polygons ) {
        // polygon.disableEdit();
        if (this.getPolygon) {
          this.getPolygon(polygons)
        }
      }
    },
    showCircle(point,meter) {
      new Promise( (resolve) =>{
        this.clearAllFences();
        resolve()
      }).then( () =>{
        this.canDrawCircle = false
        this.circleObj = L.circle(point, {
          weight: 4,
          radius: meter ? meter : 200,
          fillColor: this.theme,
          fillOpacity: 0.2,
          color: this.theme
        }).addTo(this.leafletMap);
        // this.setCenter(point,18)
        //中心点视野设置
        this.fenceList.push(this.circleObj)
      })
    },
   
    setCenterAndShowCircle(center,meter) {
      let point = center||this.point
      this.addCircle(this.getLatLng(point.lat, point.lng), meter);
    },
      /**
       * 获取最佳视野
       * @param points
       */
    setBestView(arr) {
        const points = arr.map( (item, index)=> {
            return this.getLatLng(Number(item.lat), Number(item.lng));
        });
      this.leafletMap.fitBounds(L.latLngBounds(points)); //fitBounds方法zoom过大
      const zoom = this.leafletMap.getZoom() -1 
      this.leafletMap.setZoom(zoom)
    },
     /**
     * 添加默认Marker点
     * @param point
     * @return marker 返回添加的marker点
     */
    addMarker(point,icon) {
      return new L.Marker(L.latLng({ lng: point.lng, lat: point.lat }), {
        icon: icon ,
      }).addTo(this.leafletMap);
      
    },
     /**
     * 添加地图点击事件
     * @param e
     */
    handleMapClick(e) {
      if (this.canDrawCircle) {
        this.addCircle(e.latlng)
      }
    },
    /**
     * 添加圆形围栏 拖动中 时间处理方法
     * @param e
     */
    handleFenceCircleDarwingEvent(e) {
      let center = this.circleObj.getLatLng();
      let radius = parseInt(this.circleObj.getRadius());
      this.canDrawCircle = false;
      clearTimeout(this.darwingTimeout);
      if (this.getCircle) {
          this.getCircle({
            ...center,
            radius:radius
          })
        }
      this.darwingTimeout = setTimeout(() => {
        this.canDrawCircle = true;
      }, 100)

    },
    clearAllFences() {
      this.fenceList.forEach(item => {
        item.remove()
      })
      this.fenceList = []
    },
    //根据圆形围栏meter设置合适的zoom
    setZoomByMeter(meter) {
      let zoom = 19
      if (meter < 100) {
             zoom = 19;
      } else if (meter >= 100 && meter < 150) {
          zoom = 18;
      } else if (meter >= 150 && meter < 300) {
          zoom = 17;
      } else if (meter >= 300 && meter < 600) {
          zoom = 16;
      } else if (meter >= 600 && meter < 1500) {
          zoom = 15;
      } else if (meter >= 1500 && meter < 3000) {
          zoom = 14;
      } else if (meter >= 3000 && meter < 6000) {
          zoom = 13;
      } else if (meter >= 6000 && meter < 10000) {
          zoom = 12;
      } else if (meter >= 10000 && meter < 20000) {
          zoom = 11;
      } else if (meter >= 20000 && meter < 30000) {
          zoom = 11;
      } else if (meter >= 30000 && meter < 60000) {
          zoom = 10;
      } else if (meter >= 60000 && meter < 150000) {
          zoom = 9;
      } else if (meter >= 150000 && meter < 300000) {
          zoom = 8;
      } else if (meter >= 300000 && meter < 600000) {
          zoom = 7;
      } else if (meter >= 600000 && meter < 1500000) {
          zoom = 6;
      } else if (meter >= 1500000 && meter < 3000000) {
          zoom = 5;
      } else if (meter >= 3000000 && meter < 6000000) {
          zoom = 4;
      } else if (meter >= 6000000 && meter < 7500000) {
          zoom = 3;
      } else if (meter >= 7500000 && meter < 15000000) {
          zoom = 2;
      } else if (meter >= 15000000 && meter < 30000000) {
          zoom = 1;
      } else {
          zoom = 1;
      }
      this.leafletMap.setZoom(zoom)
    },
    //视野调整
    setCenter(point, zoom) {
      if (zoom) {
        this.leafletMap.setView(point, zoom);
      } else {
        this.leafletMap.setView(point);
      }
    }
    
  }
}
</script>
<style lang="scss" >
@import '@/styles/theme.scss';
.map_footer_right_bottom{
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1000;
}
</style>