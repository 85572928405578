
import Vue from 'vue'
import axios from './router/axios'
import VueAxios from 'vue-axios'
import App from './App'
import 'github-markdown-css'
import "highlight.js/styles/github.css" //默认样式
import fileArr from './components/globalComponents/index.js'
import './permission' // 权限
import router from './router/router'
import store from './store'
import { downBlobFile, insertMsg, downFileByUrl, resetOriUrl } from './util'
import { validatenull, checkStartEnd, hasOverlap } from './util/validate'
import { base64Encrypt } from './util/encryption'
import { getLangCode } from './util/language'
import directObj from './util/directive/index'
import * as filters from './filters' // 全局filter
import getLanguage from './language'
import 'element-ui/lib/theme-chalk/index.css'
import './styles/common.scss'
import JsonViewer from 'vue-json-viewer'
import echarts from '@/const/echarts.js'
import * as L from "leaflet"
import "leaflet/dist/leaflet.css"
import en from '@/language/en'
import zh_CN from '@/language/zh_CN'
import VueMeta from 'vue-meta'
const langObj = { en, zh_CN }
// 挂载常用全局方法，import 引入
Vue.prototype.validatenull = validatenull
Vue.prototype.checkStartEnd = checkStartEnd
Vue.prototype.hasOverlap = hasOverlap
Vue.prototype.downBlobFile = downBlobFile
Vue.prototype.downFileByUrl = downFileByUrl
Vue.prototype.base64Encrypt = base64Encrypt
Vue.prototype.$insertMsg = insertMsg
Vue.prototype.getLanguage = getLanguage
Vue.prototype.$echarts = echarts
Vue.prototype.$resetOriUrl = resetOriUrl
Vue.L = Vue.prototype.$L = L

// DictResolver.install()

// 插件 json 展示
Vue.use(router)
Vue.use(JsonViewer)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true // 保证在导航时只刷新一次
})

window.axios = axios
Vue.use(VueAxios, axios)



// 加载过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
for (const key in directObj) {
  Vue.directive(key, directObj[key])
}
//组件global注册
fileArr.forEach(item => {
  Vue.component(item.name, item.compnent) //全局注册了
})
Vue.prototype.$store = store
Vue.config.productionTip = false


const langCode = getLangCode(navigator.language) 

Vue.prototype.$strings = langObj[langCode]
global.Buffer = global.Buffer || require('buffer').Buffer
getLanguage(langCode, () => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})

