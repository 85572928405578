<template>
  <div class="menu-wrapper">
    <template v-for="item in menu">
      <el-menu-item
        v-if="validatenull(item[childrenKey]) && vaildRoles(item)"
        v-show="item.visible==='1'"
        :key="item[labelKey]"
        :index="item[pathKey]"
        :class="`${getClass(item)} menu_item_title_one ${vaildAvtive(item)?'is-active':''}`"
        @click="open(item)">
        <div>
          <i :class="`iconfont icon_menu_1 ${item[iconKey]}`" />
          <span
            slot="title"
            :alt="$strings[item.code]|| item[pathKey]">{{ $strings[item.code]|| item[labelKey] }}</span>
        </div>
      </el-menu-item>
      <el-submenu
        v-else-if="!validatenull(item[childrenKey])&&vaildRoles(item)"
        v-show="item.visible==='1'"
        :key="item[labelKey] || item[pathKey] "
        :class="getParentClass(item)"
        :index="item[pathKey]">
        
        <template slot="title">
          <i :class="`iconfont icon_menu_1 ${item[iconKey]}`" />
          <span
            slot="title"
            :class="{'el-menu--display':collapse && first}">{{ $strings[item.code]|| item[labelKey] }}</span>
        </template>
        <template v-for="(child,cindex) in item[childrenKey]">
          <el-menu-item
            v-if="validatenull(child[childrenKey])"
            v-show="child.visible==='1'"
            :key="child[labelKey]"
            :index="child[pathKey] + cindex"
            :class="` ${getClass(child)}`"
            @click="open(child)">
            <i :class="`iconfont icon_menu_2  ${child[iconKey]}`" />
            <el-tooltip slot="title" :over-width="100" :content="$strings[child.code]|| child[labelKey] ">
              <span>{{ $strings[child.code]|| child[labelKey] }}</span>
            </el-tooltip>
            
          </el-menu-item>
          <sidebar-item
            v-else
            :key="cindex"
            :menu="[child]"
            :props="props"
            :screen="screen"
            :collapse="collapse" />
        </template>
      </el-submenu>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import config from './config.js'
export default {
  name: 'SidebarItem',
  props: {
    menu: {
      type: Array,
      default: () => []
    },
    screen: {
      type: Number,
      default: 0
    },
    first: {
      type: Boolean,
      default: false
    },
    props: {
      type: Object,
      default: () => {
        return {}
      }
    },
    collapse: {
      type: Boolean
    }
  },
  data() {
    return {
      config: config,
      currentPath: ''
    }
  },
  computed: {
    ...mapGetters(['roles', 'tag', 'parentTag', 'keyCollapse']),
    labelKey() {
      return this.props.label || this.config.propsDefault.label
    },
    pathKey() {
      return this.props.path || this.config.propsDefault.path
    },
    iconKey() {
      return this.props.icon || this.config.propsDefault.icon
    },
    childrenKey() {
      return this.props.children || this.config.propsDefault.children
    },
    nowTagValue() {
      return this.$router.$avueRouter.getValue(this.$route)
    }
  },
  created() {
    this.currentPath = this.tag.value
    this.$router.push({
      path: this.$router.$avueRouter.getPath({
        name: this.tag.label,
        src: this.tag.value
      }),
      query: this.tag.query
    }).catch(() => {})
  },
  methods: {
    vaildAvtive(item) {
      const groupFlag = (item['group'] || []).some(ele =>
        this.$route.path.includes(ele)
      )
      
      return this.nowTagValue === item[this.pathKey] || groupFlag
    },
    vaildRoles(item) {
      item.meta = item.meta || {}
      return item.meta.roles ? item.meta.roles.includes(this.roles) : true
    },
    getClass(item) {
      return item.path === this.nowTagValue ? 'click_menu' : ''
    },
    getParentClass(item) {
      return item.code === this.parentTag.code ? 'open_menu' : ''
    },
    open(item) {
      this.currentPath = item.path
      if (this.screen <= 1) this.$store.commit('SET_COLLAPSE', !this.keyCollapse)
      this.$router.$avueRouter.group = item.group
      this.$router.push({
        path: this.$router.$avueRouter.getPath({
          name: item[this.labelKey],
          src: item[this.pathKey]
        }),
        query: item.query
      }).catch(() => {})
    }
  }
}
</script>

