import request from '@/router/axios'

export function fetchList(query) {
  return request({
    url: 'device/firmwareOta/page',
    method: 'get',
    params: query
  })
}
export function fetchAllList(query) {
  return request({
    url: 'device/firmwareOta/list',
    method: 'get',
    params: query
  })
}
export function fetchLogList(id, query) {
  return request({
    url: 'device/firmwareOta/pageQueryUpgradeHistory/' + id,
    method: 'get',
    params: query
  })
}

export function fetchInfoList(id, query) {
  return request({
    url: 'device/deviceInfo/pagefirmwareOta/' + id,
    method: 'get',
    params: query
  })
}

export function addObj(obj) {
  return request({
    url: '/device/firmwareOta',
    method: 'post',
    data: obj
  })
}

export function getObj(id) {
  return request({
    url: '/device/firmwareOta/' + id,
    method: 'get'
  })
}
export function getUpObj(id) {
  return request({
    url: '/push/messageRecord/getPushConfig/' + id,
    method: 'get'
  })
}

export function delObj(id) {
  return request({
    url: '/device/firmwareOta/' + id,
    method: 'delete'
  })
}

export function putObj(obj) {
  return request({
    url: '/device/firmwareOta',
    method: 'put',
    data: obj
  })
}
export function applyUpgrade(obj) {
  return request({
    url: '/device/firmwareOta/push',
    method: 'post',
    data: obj
  })
}
export function auditShelf(obj) {
  return request({
    url: '/device/firmwareOta/auditShelf',
    method: 'put',
    data: obj
  })
}

export function applyOnShelf(id) {
  return request({
    url: '/device/firmwareOta/applyOnShelf/' + id,
    method: 'put'
  })
}
export function applyReshelve(id) {
  return request({
    url: '/device/firmwareOta/onlineAgain/' + id,
    method: 'put'
  })
}
export function cancelApplyOnShelf(id) {
  return request({
    url: '/device/firmwareOta/cancelApplyOnShelf/' + id,
    method: 'put'
  })
}
export function applyOffShelf(id) {
  return request({
    url: '/device/firmwareOta/offline/' + id,
    method: 'put'
  })
}
export function parseXml(obj) {
  return request({
    url: '/device/firmwareOta/parseXml',
    method: 'post',
    data: obj
  })
}
