<template>
  <div ref="echarts" id="echarts" :style="{height:heightNum+'px',width:'100%'}"></div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'EchartsCircle',
  components: {
  },
  props: {
    title: {
        type: String,
        default:''
    },
    seriesName: {
      type: String,
      default:''
    },
    subText: {
      type: String,
      default:''
    },
    seriesData: {
      type: Array,
      default:[
              { value: 1048, name: 'Search Engine' },
              { value: 735, name: 'Direct' },
              { value: 580, name: 'Email' },
              { value: 484, name: 'Union Ads' },
              { value: 300, name: 'Video Ads' }
            ]
    }
  },
  data() {
    return {
      heightNum:560
    }
  },
  watch: {
    innerHeight() {
      if (this.$refs.echarts) {
        let num ;
        num = this.innerHeight - this.$refs.echarts.offsetTop - 86
        if (document.getElementById('tms_footer').childNodes.length){//判断底部footer是否存在
          num-=15
        }
        this.heightNum = num
        
      }
    }
  },
  computed: {
    ...mapGetters([
      'theme',
      'innerHeight',
      'innerWidth'
    ])
  },
  created() {
    this.$nextTick(() => {
      let num;
      num = this.innerHeight - this.$refs.echarts.offsetTop - 86
      if (document.getElementById('tms_footer').childNodes.length){
          num-=15
      }
      this.heightNum = num
    })
  },
  beforeDestroy() {
    this.timeOut && clearTimeout(this.timeOut)
  },
  mounted() {
    this.initOptions()
  },
  methods: {
    initOptions() {
      this.options ={
        title: {
          text: this.title,
          subtext:this.subText,
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          left: 'right',
          top:'top'
        },
        labelLine: {//线段长度配置
          length: 30
        },
        label: {
          formatter: `{b|{b}}\n{hr|}\n  ${this.$strings.number}：{c}  ${this.$strings.percentage}：{per|{d}%} `,
          backgroundColor: '#F6F8FC',
          borderColor: '#8C8D8E',
          borderWidth: 1,
          borderRadius: 4,
          rich: {
            a: {
              color: '#6E7079',
              lineHeight: 22,
              align: 'center',
              left:'center'
            },
            hr: {
              borderColor: '#8C8D8E',
              width: '100%',
              borderWidth: 1,
              height: 0
            },
            b: {
              color: '#4C5058',
              fontSize: 14,
              fontWeight: 'bold',
              lineHeight: 34,
              align: 'center',
            },
            per: {
              color: '#4C5058',
              fontSize: 14,
              lineHeight: 34
            }
          }
      },
        series: [
          {
            name: this.seriesName,
            type: 'pie',
            radius: '50%',
            data: this.seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      this.initCharts()
    },
    //初始化echarts
    initCharts() {
      this.chart = this.$echarts.init(document.getElementById('echarts'))
      this.chart.resize()
      this.chart.setOption(this.options)
      window.echartResize = () => {
        this.timeOut = setTimeout(() => {
          this.chart.resize()
        }, 201)
        
      }
    },
  }
}
</script>
<style lang="scss" >
@import '@/styles/theme.scss';
</style>