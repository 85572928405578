var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "map_wrap", attrs: { id: _vm.mapId } },
    [
      _c(
        "div",
        { staticClass: "map_footer_right_bottom wid_100" },
        [_vm._t("footer")],
        2
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }