<template>
  <div >
    <tms-dialog
      ref="dialog_tms"
      width="887px"
      @cancel="cancel"
      @submit="submit"
      :title="title"
      
    >
    <div class="just_between" v-loading="loading">
      <simpleTree 
        ref="all_choose_tree"
        :leftTitle="$strings.all_permission"
        :showCheckbox="true"
        :data="roleMenu"
        otherName="border_tree"
        :needCode="true"
        @getTreeByCheckeds = "getTreeByCheckeds"
        :innerPropHeight="innerPropHeight"
      />
      <simpleTree 
      :leftTitle="$strings.checked_permission"
      rightType="delete"
      otherName="border_tree border_left_none"
      :data="dataList"
      :needCode="true"
      @deleteAll="deleteAll"
      :removeByKey="removeByKey"
      :innerPropHeight="innerPropHeight"
      />
    </div>
    </tms-dialog>
  </div>
</template>
  
<script>
import tmsDialog from '../dialog/index.vue'
import simpleTree from '../simpleTree/index.vue'
let el
export default {
  name: 'Treecompany',
  components: {
    tmsDialog,
    simpleTree
  },
  props: {
    roleMenu: {
      default:()=>[]
    },
    title: {
      default: ''
    },
    innerPropHeight: {
      default:''
    },
  },
  data() {
    return {
      visible: false,
      dataList: [],
      checkedKeys: [],
      loading:false
    }
  },
  computed: {},
  watch: {

  },
  created() {
    el=this
  },
  methods: {
    show() {
      this.loading =true
      this.dataList = []
      this.$refs.dialog_tms.show()
    },
    cancel() {
      this.$refs.dialog_tms.visible = false
      
    },
    submit() {
      this.$refs.dialog_tms.visible=false
      this.$emit('submit')
    },
    //设置该组件默认的key
    setDefaultKeys(checkedKeys) {
     
      const leaf_checkedKeys = this.resolveAllEunuchNodeId(this.roleMenu, checkedKeys, [])
      this.$refs.all_choose_tree.setCheckedKeys(leaf_checkedKeys)
      this.getTreeByCheckeds(this.roleMenu, leaf_checkedKeys)
      this.loading=false
    },
    getKeys() {
      return this.getCheckedToData(this.dataList)
    },
    getCheckedToData(data) {
      const arr = []
      data.map(item => {
        if (item.children) {
          arr.push(item.id)
           arr.push(...this.getCheckedToData(item.children))
        } else {
          arr.push(item.id)
        }
      })
      return arr
    },
     /**
     * 解析出所有的太监节点id
     * @param json 待解析的json串
     * @param idArr 原始节点数组
     * @param temp 临时存放节点id的数组
     * @return 太监节点id数组
     */
     resolveAllEunuchNodeId(json, idArr, temp) {
      for (let i = 0; i < json.length; i++) {
        const item = json[i]
        // 存在子节点，递归遍历;不存在子节点，将json的id添加到临时数组中
        if (item.children && item.children.length !== 0) {
          this.resolveAllEunuchNodeId(item.children, idArr, temp)
        } else {
          temp.push(idArr.filter(id => id === item.id))
        }
      }
      return temp
    },
    /**
     * 根据被选中数据获取新的树结构
     * @param {*} orgData 树初始数据
     * @param {*} checkedKeys 被选中的数据
     */
    getTreeByCheckeds(orgData, checkedKeys) {
      this.checkedKeys = checkedKeys
      if (!checkedKeys.length) {//没有选中数据时
        this.dataList = []
      }
      let currentOrgs = JSON.parse(JSON.stringify(orgData))
      //有选中数据时，调用此方法
      this.dataList = this.deleteUnCheckedKey(currentOrgs, checkedKeys)
    },
    /**
     * 删除未选中数据
     * @param {*} children 子集数据
     * @param {*} checkedKeys 被选中的数据
     */
    deleteUnCheckedKey(children, checkedKeys) {
      const arr = []
       children.forEach(item => {
        //当前节点有子集并且子集中有数据被选中
        if (item.children && this.checkKeysInChildren(item, checkedKeys)) {
          item.children = this.deleteUnCheckedKey(item.children,checkedKeys)
          arr.push(item) 
        } else if(checkedKeys.indexOf(item.id)!==-1){//当前节点被选中
          arr.push(item) 
        }
       })
      return arr
      
    },
    //判断当前节点及其子集是否包含选中的key
    checkKeysInChildren(dataItem, checkedKeys) {
      let flag;
      if (checkedKeys.indexOf(dataItem.id) !== -1) {//当前节点包含  返回true
        flag= true
      } else if (dataItem.children) {
        dataItem.children.forEach(item => {//遍历子节点
          flag = flag? true: this.checkKeysInChildren(item,checkedKeys) //子节点有一个被选中返回true
        })
      }
      return flag
    },
    //右侧删除全部选中菜单
    deleteAll() {
      this.$refs.all_choose_tree.checkedKeys = []
      this.dataList = []
    },
    removeByKey(key) {
      this.checkedKeys = this.checkedKeys.filter(item => item !== key)
      this.setDefaultKeys(this.checkedKeys)
    }
  }
}
</script>
  <style lang="scss">
  @import '@/styles/theme.scss';
    .border_tree{
      border: 1px solid;
      @extend .border_3;
    }
    .border_left_none{
      border-left: none;
    }
  </style>
