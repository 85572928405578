<template>
  <div>
    <input :id="`select${new Date()*1}`" ref="input" v-show="false" :accept="accept" type="file" @change="handleFiles" >
    <div ref="screen_all" class="screen_all" 
    v-show="loading"
     >
     <div class="screen_upload_wrap  " v-if="loadingObj.percent">
      <div class="just_center ">
        <div class="m_r_12">
          <el-progress type="circle" :color="theme" :percentage="loadingObj.percent"></el-progress>
        </div>
        <div>
          <p class="tal  li_h_40">{{ loadingObj.uploadedSize }} / {{ this.loadingObj.totalSize }}</p>
          <p class="tal li_h_40">{{$strings.current_speed}}:{{ loadingObj.speed }}</p>
          <p class="tal ">{{$strings.remaining_time}}:{{ loadingObj.remainingTime }}</p>
        </div>
      </div>
      <el-button type="primary" class="m_t_14" @click="handleRemoveFile">{{$strings.cancel_upload}}</el-button>
     </div>
     <div v-if="!loadingObj.percent" class="screen_upload_wrap font_s_16">
      <i class="el-icon-loading font_s_24" /><br/>
      <span>{{$strings.file_parsing}}</span> <br/>
      <el-button class="m_t_14" type="primary" :disabled="uploadLoading" @click="handleRemoveFile">{{$strings.cancel_upload}}</el-button>
    </div>
    
    </div>
  </div>
    
</template>

<script >
import md5 from "./md5";
import {sizeTostr} from '@/util/util'
import { taskInfo, initTask, preSignUrl, merge ,stopFile,deleteMd5File} from '@/api/admin/upload';
import Queue from 'promise-queue-plus';
import axios from "axios";
import {mapGetters} from 'vuex'
export default {
  props: {
    accept: {
      type: String,
      default:''
    },
    bucket: {
      type: String,
      default:'app'
    },
    checkApk: {
      type: Function,
      default:null
    }
  },
  data() {
    return {
      fileUploadChunkQueue: {},
      loading: false,
      loadingObj: {},
      identifier: '',
      uploadLoading:true
    }
  },
  computed: {
    ...mapGetters(['theme'])
  },
  created() {
  },
  beforeDestroy() {
    this.identifier&&this.stop(this.identifier)
    this.close()
  },
  mounted(){
    document.body.append(this.$refs['screen_all'])
  },
  methods: {
    getFile() {
      this.$refs.input.click()
    },
    /**
 * 获取一个上传任务，没有则初始化一个
 */
 async getTaskInfo  (file)  {
    let task;
   this.loading = true
   const identifier = await md5(file)
   this.uploadLoading = false
   this.identifier = identifier
   if (!this.loading) return

    const res = await taskInfo(identifier)
    const { code, data, msg } = res.data
    if (code === 0) {
        task = data
        if (!task) {
            const initTaskData = {
                identifier,
                fileName: this.getFileKey(file.name,identifier),
                totalSize: file.size,
                chunkSize: 5 * 1024 * 1024,
                directory:`${this.bucket}/${new Date()*1}`
            }
          const res = await initTask(initTaskData)
          const { code, data, msg } = res.data
            if (code === 0) {
                task = data
            } else {
              this.loading = false
              this.uploadLoading = true
            }
        }
    } else {
      this.loading = false
      this.uploadLoading = true
    }
    return task
},

/**
 * 上传逻辑处理，如果文件已经上传完成（完成分块合并操作），则不会进入到此方法中
 */
 handleUpload (file, taskRecord) {
  
    let lastUploadedSize = 0; // 上次断点续传时上传的总大小
    let uploadedSize = 0 // 已上传的大小
    const totalSize = file.size || 0 // 文件总大小
    let startMs = new Date().getTime(); // 开始上传的时间
    const { exitPartList, chunkSize, chunkNum, fileMd5 } = taskRecord

    // 获取从开始上传到现在的平均速度（byte/s）
    const getSpeed = () => {
        // 已上传的总大小 - 上次上传的总大小（断点续传）= 本次上传的总大小（byte）
        const intervalSize = uploadedSize - lastUploadedSize
        const nowMs = new Date().getTime()
        // 时间间隔（s）
        const intervalTime = (nowMs - startMs) / 1000
        return intervalSize / intervalTime
    }

    const uploadNext = async (partNumber) => {
        const start = new Number(chunkSize) * (partNumber - 1)
        const end = start + new Number(chunkSize)
        const blob = file.slice(start, end)
      const res = await preSignUrl({ identifier: fileMd5, partNumber: partNumber })
      let { code, data, msg } = res.data
      if (code === 0 && data) {
            await axios.request({
                url: data,
                method: 'PUT',
                data: blob,
              withCredentials: false, 
                noloading: true,  // 不显示loading
                headers: { isToken: false, 'Content-Type': 'application/octet-stream'}
            })
            return Promise.resolve({ partNumber: partNumber, uploadedSize: blob.size })
        }
        return Promise.reject(`分片${partNumber}， 获取上传地址失败`)
    }

    /**
     * 更新上传进度
     * @param increment 为已上传的进度增加的字节量
     */
   const updateProcess = (increment) => {
        increment = new Number(increment)
        // const { onProgress } = options
        let factor = 1000; // 每次增加1000 byte
        let from = 0;
        // 通过循环一点一点的增加进度
        while (from <= increment) {
            from += factor
            uploadedSize += factor
          const percent = Math.round(uploadedSize / totalSize * 100).toFixed(2) * 1
          if (percent >= 0 && percent <= 100) {
             this.$set(this.loadingObj,'percent',percent)
          }
         
        }

        const speed = getSpeed();
        const remainingTime = speed != 0 ? Math.ceil((totalSize - uploadedSize) / speed) + 's' : '未知'
        this.loadingObj.totalSize = sizeTostr(totalSize)
        this.loadingObj.uploadedSize = sizeTostr(uploadedSize)
        this.loadingObj.speed = (speed / 1024 / 1024).toFixed(2) + 'mbps'
        this.loadingObj.remainingTime =remainingTime
    }


   return new Promise(resolve => {
          
          const failArr = [];
          const queue = Queue(5, {
              "retry": 3,               //Number of retries
              "retryIsJump": false,     //retry now?
              "workReject": function(reason,queue){
                  failArr.push(reason)
              },
              "queueEnd": function(queue){
                  resolve(failArr);
              }
          })
          this.fileUploadChunkQueue[file.uid] = queue
          for (let partNumber = 1; partNumber <= chunkNum; partNumber++) {
              const exitPart = (exitPartList || []).find(exitPart => exitPart.partNumber == partNumber)
              if (exitPart) {
                  // 分片已上传完成，累计到上传完成的总额中,同时记录一下上次断点上传的大小，用于计算上传速度
                  lastUploadedSize += new Number(exitPart.size)
                  updateProcess(exitPart.size)
              } else {
                  queue.push(() => uploadNext(partNumber).then(res => {
                      // 单片文件上传完成再更新上传进度
                      updateProcess(res.uploadedSize)
                  })).catch(error => {
                  })
              }
          }
          if (queue.getLength() == 0) {
              // 所有分片都上传完，但未合并，直接return出去，进行合并操作
              resolve(failArr);
              return;
          }
          queue.start()
      })
    },

    async handleFiles(e) {//文件上传
      const file = e.target.files[0]
      // //获取文件名后缀
      // const suffix = file.name.split('.').pop()
      // //判断是否为当前accept类型
      // if (this.accept && this.accept.indexOf(suffix) === -1) {
      //   this.$message.error(this.$strings.file_type_error)
      //   this.clearInput()
      //   return
      // }
      let checkFlag = true
      const fileName = file.name;
      const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
      const ext = `.${fileExtension}`
      
      if(this.accept.indexOf(ext) == -1){
        this.$message.error(this.$strings.data_update_error)
        return
      }
      if (this.checkApk) {
        checkFlag =await this.checkApk(file)
      }
      if (!checkFlag) {
        this.clearInput()
        return
      }
      const task = await this.getTaskInfo(file)
      if (task) {
          const { finished, path, taskRecord } = task
          const { fileMd5: identifier } = taskRecord
        if (finished) {
            this.getFileUrl(path, taskRecord,file)
              return path
          } else {
              const errorList = await this.handleUpload(file, taskRecord)
          if (errorList.length > 0) {
            this.loading = false
            this.uploadLoading = true
                  this.$message.error(this.$strings.file_try_again)
                  return;
              }
            const res = await merge(identifier)
            const { code, data, msg } = res.data
          if (code === 0) {
            this.getFileUrl(path, taskRecord,file)
                  return path;
          } else {
            this.loading = false
            this.uploadLoading = true
              }
          }
      } else {
        this.loading = false
        this.uploadLoading = true
      }
    },
    /**
     * 移除文件列表中的文件
     * 如果文件存在上传队列任务对象，则停止该队列的任务
    */
    handleRemoveFile() {
      this.close()
      this.identifier && this.stop(this.identifier)
    },
    getFileUrl(url, obj, file) {
      this.close()
      this.$emit('getUrl', {
            url: url,
            fileMd5:obj.fileMd5,
            fileSize: obj.totalSize,
            key: obj.fileName,
            file
          })
    },
    clearInput() {
      this.$refs.input.value=''
    },
    getFileKey(name, md5) {
      if (!name) return
      const nameArr = name.split('.')
      const accept = nameArr[nameArr.length - 1]
      return `${md5}.${accept}`
    },
    stop(md5) {
      stopFile(md5)
        .then(res => {
          this.clearInput()
        })
        .catch(error => {
        })
    },
    close() {
      this.loadingObj = {}
      this.loading = false
      this.uploadLoading = true
      this.identifier=''
      //终止队列任务
      for (const key in this.fileUploadChunkQueue) {
        this.fileUploadChunkQueue[key]&&this.fileUploadChunkQueue[key].stop()
        this.fileUploadChunkQueue[key] = undefined
      }
      //清空input内容
      this.clearInput()

    },
    delete(md5) {
      deleteMd5File(md5)
        .then(res => {
          this.clearInput()
        })
        .catch(error => {
        })
    }
    
  }
}
</script>
<style lang="scss">
@import '@/styles/theme.scss';
.screen_all{
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 30001;
  .screen_upload_wrap{
    width: 500px;
    @extend .font_5;
    margin: 50vh auto;
    text-align: center;
    transform: translateY(-50%);
    .el-progress__text{
      @extend .font_5;
    }
    p{
      margin: 0 !important;
    }
  }
  .close_upload{
    position: absolute;
    font-size: 24px;
    right: 30px;
    top: 30px;
  }
}
</style>

