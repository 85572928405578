<template>
  <div>
    <div
      v-if="!menu"
      class="avue-sidebar--tip">{{ $strings.menu_i18n_no }}</div>
    <el-menu
      v-if="menu"
      ref="menus"
      v-resize="resize"
      :default-active="nowTagValue"
      :show-timeout="200"
      :collapse="keyCollapse"
      :default-openeds="nowOpens"
      unique-opened
      :collapse-transition="false"
      mode="vertical">
      <sidebar-item
        :menu="menu"
        :screen="screen"
        :props="website.menu.props"
        :collapse="keyCollapse"
        first />
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import sidebarItem from './sidebarItem'
import { deepClone } from '@/util'
export default {
  name: 'Sidebar',
  components: { sidebarItem },
  inject: ['Index'],
  data: () => {
    return {
      nowOpens: []
    }
  },
  computed: {
    ...mapGetters(['website', 'menu', 'tag', 'keyCollapse', 'screen', 'parentTag']),
    nowTagValue: function() {
      return this.$router.$avueRouter.getValue(this.$route)
    }
  },
  watch: {
    keyCollapse() {
      const value = this.getOpens()
      this.$refs.menus && this.$refs.menus.open(value)
    }
  },
  created() {
    this.Index.openMenu(this.menuId)
  },
  mounted() {
    setTimeout(() => {
      this.nowOpens = [this.getOpens()]
    }, 500)
    
  },
  methods: {
    getOpens() {
      const newMenu = deepClone(this.menu)
      if (!newMenu) return ''
      const valueArr = newMenu.filter(item => item.label === this.parentTag.label)
      const pathValue = valueArr.length ? valueArr[0].path : ''
      return pathValue
    },
    resize(e) {
      this.$store.commit('SET_SUBWIDTH', e)
    }
  }
}
</script>
<style lang="scss">
@import '@/styles/theme.scss';
.icon_menu_1{
  font-size: 16px !important;
  margin:0 18px 0 5px !important;
}
.icon_menu_2{
 
  font-size: 14px !important;
  margin:0 18px 0 5px !important;
  opacity: 0.8;
}
.click_menu{
  background: #165DFF;
  @extend .font_5;
  i{
    @extend .font_5;
  }
}
.click_menu:hover,.click_menu:focus{
  background: #165DFF !important;
  @extend .font_5;
  i{
    @extend .font_5;
  }
}
</style>

