import Layout from '@/pages/layout/'
// import DocsLayout from '@/pages/docs/'
export default [
  // {
  //   path: '/docs',
  //   component: DocsLayout,
  //   name: '数据中心',
  //   code: 'menu_i18n_device_center',
  //   children: [
  //     {
  //       path: '/docs/read/index',
  //       name: '设备详情',
  //       meta: { code: 'menu_i18n_device_detail' },
  //       component: () =>
  //         import(/* webpackChunkName: "page" */ '@/docs/read/index')
  //     }
  //   ]
  // },
  {
    path: '/device',
    component: Layout,
    name: 'menu_i18n_device_center',
    code: 'menu_i18n_device_center',
    children: [
    
      {
        path: '/device/deviceMgt/detail',
        name: 'menu_i18n_device_detail',
        meta: { code: 'menu_i18n_device_detail' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/device/deviceMgt/detail')
      },
      {
        path: '/device/deviceMgt/diagnosis',
        name: 'menu_i18n_diagnosis',
        meta: { code: 'menu_i18n_diagnosis' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/device/deviceMgt/diagnosis')
      },
      {
        path: '/device/deviceMgt/diagnosisControl',
        name: 'menu_i18n_diagnosis_device',
        meta: { code: 'menu_i18n_diagnosis_device' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/device/deviceMgt/diagnosisControl')
      },
      {
        path: '/device/deviceMgt/control',
        name: 'menu_i18n_control',
        meta: { code: 'menu_i18n_control' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/device/deviceMgt/control')
      },
      {
        path: '/device/group/list',
        name: 'menu_i18n_has_devices',
        meta: { code: 'menu_i18n_has_devices' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/device/group/list')
      },
      {
        path: '/device/group/deviceList',
        name: 'menu_i18n_add_device',
        meta: { code: 'menu_i18n_add_device' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/device/group/deviceList')
      },
      {
        path: '/device/group/deptList',
        name: 'menu_i18n_store_add_device',
        meta: { code: 'menu_i18n_store_add_device' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/device/group/deptList')
      }
    ]
  },
  {
    path: '/push',
    component: Layout,
    name: 'menu_i18n_push',
    meta: { code: 'menu_i18n_push' },
    children: [
      {
        path: '/push/log',
        name: 'menu_i18n_push_log',
        meta: { code: 'menu_i18n_push_log' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/push/components/log')
      },
      {
        path: '/push/detail',
        name: 'push_device_detail',
        meta: { code: 'push_device_detail' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/push/components/deviceDetail')
      },
      {
        path: '/push/wifi/detail',
        name: 'menu_i18n_wifi_detail',
        meta: { code: 'menu_i18n_wifi_detail' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/push/wifiSetting/detail')
      },
      {
        path: '/push/apnSetting/add',
        name: 'menu_i18n_apn_add',
        meta: { code: 'menu_i18n_apn_add' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/push/apnSetting/addApn')
      },
      {
        path: '/push/apnSetting/change',
        name: 'menu_i18n_apn_change',
        meta: { code: 'menu_i18n_apn_change' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/push/apnSetting/changeApn')
      },
      {
        path: '/push/apnSetting/detail',
        name: 'menu_i18n_apn_detail',
        meta: { code: 'menu_i18n_apn_detail' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/push/apnSetting/detailApn')
      },
      {
        path: '/push/filePush/add',
        name: 'menu_i18n_file_add',
        meta: { code: 'menu_i18n_file_add' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/push/filePush/addFile')
      },
      {
        path: '/push/filePush/change',
        name: 'menu_i18n_file_change',
        meta: { code: 'menu_i18n_file_change' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/push/filePush/changeFile')
      },
      {
        path: '/push/filePush/detail',
        name: 'menu_i18n_file_bootInterface_detail',
        meta: { code: 'menu_i18n_file_detail' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/push/filePush/detailFile')
      },
      {
        path: '/push/bootInterface/add',
        name: 'menu_i18n_bootInterface_add',
        meta: { code: 'menu_i18n_apn_add' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/push/bootInterface/add')
      },
      {
        path: '/push/bootInterface/change',
        name: 'menu_i18n_bootInterface_change',
        meta: { code: 'menu_i18n_apn_change' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/push/bootInterface/change')
      },
      {
        path: '/push/bootInterface/detail',
        name: 'menu_i18n_bootInterface_detail',
        meta: { code: 'menu_i18n_apn_detail' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/push/bootInterface/detail')
      },
      {
        path: '/push/appHidden/add',
        name: 'menu_i18n_appHidden_add',
        meta: { code: 'menu_i18n_appHidden_add' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/push/appHidden/add')
      },
      {
        path: '/push/appHidden/change',
        name: 'menu_i18n_appHidden_change',
        meta: { code: 'menu_i18n_apn_change' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/push/appHidden/change')
      },
      {
        path: '/push/appHidden/detail',
        name: 'menu_i18n_appHidden_detail',
        meta: { code: 'menu_i18n_apn_detail' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/push/appHidden/detail')
      }
     
    ]
  },
  {
    path: '/app',
    component: Layout,
    name: 'menu_i18n_app_store',
    meta: { code: 'menu_i18n_app_store' },
    
    children: [
      {
        path: '/app/manage/add',
        name: 'menu_i18n_app_add',
        meta: { code: 'menu_i18n_app_add' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/app/manage/addApp')
      },
      {
        path: '/app/manage/addVersion',
        name: 'menu_i18n_version_add',
        meta: { code: 'menu_i18n_version_add' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/app/manage/addVersion')
      },
      {
        path: '/app/cert/add',
        name: 'menu_i18n_cert_add',
        meta: { code: 'menu_i18n_cert_add' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/app/cert/addCert')
      },
      {
        path: '/app/cert/detail',
        name: 'menu_i18n_cert_detail',
        meta: { code: 'menu_i18n_cert_detail' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/app/cert/detail')
      },
      {
        path: '/app/advManage/add',
        name: 'menu_i18n_adv_add',
        meta: { code: 'menu_i18n_adv_add' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/app/advManage/addAdv')
      },
      {
        path: '/app/advManage/change',
        name: 'menu_i18n_adv_change',
        meta: { code: 'menu_i18n_adv_change' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/app/advManage/changeAdv')
      },
      {
        path: '/app/advManage/check',
        name: 'menu_i18n_adv_check',
        meta: { code: 'menu_i18n_adv_check' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/app/advManage/checkAdv')
      },
      {
        path: '/app/manage/change',
        name: 'menu_i18n_app_change',
        meta: { code: 'menu_i18n_app_change' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/app/manage/changeApp')
      },
      {
        path: '/app/manage/detail',
        name: 'menu_i18n_app_detail',
        meta: { code: 'menu_i18n_app_detail' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/app/manage/detailApp')
      },
      {
        path: '/app/manage/audio',
        name: 'menu_i18n_app_audio',
        meta: { code: 'menu_i18n_app_audio' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/app/manage/audioApp')
      },
      {
        path: '/app/manage/versionList',
        name: 'menu_i18n_version_list',
        meta: { code: 'menu_i18n_version_list' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/app/manage/versionList')
      },
      {
        path: '/app/am/detail',
        name: 'menu_i18n_file_detail',
        meta: { code: 'menu_i18n_file_detail' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/app/am/detailApn')
      },
      {
        path: '/app/watch/detail',
        name: 'detail',
        meta: { code: 'detail' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/app/watch/detail')
      }
     
    ]
  },
  {
    path: '/ota',
    component: Layout,
    name: 'menu_i18n_ota_manage',
    meta: { code: 'menu_i18n_ota_manage' },
    children: [
      
      {
        path: '/ota/add',
        name: 'menu_i18n_ota_add',
        meta: { code: 'menu_i18n_ota_add' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/ota/addApp')
      },
      {
        path: '/ota/change',
        name: 'menu_i18n_ota_change',
        meta: { code: 'menu_i18n_ota_change' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/ota/changeApp')
      },
      {
        path: '/ota/detail',
        name: 'menu_i18n_ota_detail',
        meta: { code: 'menu_i18n_ota_detail' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/ota/detailApp')
      },
      {
        path: '/ota/log',
        name: 'menu_i18n_ota_log',
        meta: { code: 'menu_i18n_ota_log' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/ota/log')
      },
      {
        path: '/ota/audio',
        code: 'menu_i18n_ota_audio',
        meta: { code: 'menu_i18n_ota_audio' },
        name: 'menu_i18n_ota_audio',
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/ota/audioApp')
      }
     
    ]
  },
  {
    path: '/map',
    component: Layout,
    name: 'menu_i18n_device_map',
    meta: { code: 'menu_i18n_device_map' },
    children: [
      
      {
        path: '/map/fence/add',
        name: 'menu_i18n_add_fence',
        meta: { code: 'menu_i18n_add_fence' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/map/fence/map')
      },
      {
        path: '/map/store/add',
        name: 'menu_i18n_add_fence',
        meta: { code: 'menu_i18n_add_fence' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/map/store/map')
      },
      {
        path: '/map/store/change',
        name: 'menu_i18n_change_fence',
        meta: { code: 'menu_i18n_change_fence' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/map/store/changeMap')
      },
      {
        path: '/map/store/log',
        name: 'menu_i18n_change_log',
        meta: { code: 'menu_i18n_change_log' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/map/store/log')
      },
      {
        path: '/map/fence/change',
        name: 'menu_i18n_change_fence',
        meta: { code: 'menu_i18n_change_fence' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/map/fence/changeMap')
      },
      {
        path: '/map/fence/connect',
        name: 'menu_i18n_connect_fence',
        meta: { code: 'menu_i18n_connect_fence' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/map/fence/addDeviceList')
      },
      {
        path: '/map/alarm/log',
        name: 'menu_i18n_fence_alarm',
        meta: { code: 'menu_i18n_fence_alarm' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/map/alarm/index')
      },
      {
        path: '/map/alarm/detail',
        name: 'menu_i18n_check_alarm',
        meta: { code: 'menu_i18n_check_alarm' },
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/map/alarm/map')
      }
      
    ]
  }
  
]
