var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-wrapper" },
    [
      _vm._l(_vm.menu, function (item) {
        return [
          _vm.validatenull(item[_vm.childrenKey]) && _vm.vaildRoles(item)
            ? _c(
                "el-menu-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.visible === "1",
                      expression: "item.visible==='1'",
                    },
                  ],
                  key: item[_vm.labelKey],
                  class: `${_vm.getClass(item)} menu_item_title_one ${
                    _vm.vaildAvtive(item) ? "is-active" : ""
                  }`,
                  attrs: { index: item[_vm.pathKey] },
                  on: {
                    click: function ($event) {
                      return _vm.open(item)
                    },
                  },
                },
                [
                  _c("div", [
                    _c("i", {
                      class: `iconfont icon_menu_1 ${item[_vm.iconKey]}`,
                    }),
                    _c(
                      "span",
                      {
                        attrs: {
                          slot: "title",
                          alt: _vm.$strings[item.code] || item[_vm.pathKey],
                        },
                        slot: "title",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$strings[item.code] || item[_vm.labelKey])
                        ),
                      ]
                    ),
                  ]),
                ]
              )
            : !_vm.validatenull(item[_vm.childrenKey]) && _vm.vaildRoles(item)
            ? _c(
                "el-submenu",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.visible === "1",
                      expression: "item.visible==='1'",
                    },
                  ],
                  key: item[_vm.labelKey] || item[_vm.pathKey],
                  class: _vm.getParentClass(item),
                  attrs: { index: item[_vm.pathKey] },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", {
                      class: `iconfont icon_menu_1 ${item[_vm.iconKey]}`,
                    }),
                    _c(
                      "span",
                      {
                        class: {
                          "el-menu--display": _vm.collapse && _vm.first,
                        },
                        attrs: { slot: "title" },
                        slot: "title",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$strings[item.code] || item[_vm.labelKey])
                        ),
                      ]
                    ),
                  ]),
                  _vm._l(item[_vm.childrenKey], function (child, cindex) {
                    return [
                      _vm.validatenull(child[_vm.childrenKey])
                        ? _c(
                            "el-menu-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: child.visible === "1",
                                  expression: "child.visible==='1'",
                                },
                              ],
                              key: child[_vm.labelKey],
                              class: ` ${_vm.getClass(child)}`,
                              attrs: { index: child[_vm.pathKey] + cindex },
                              on: {
                                click: function ($event) {
                                  return _vm.open(child)
                                },
                              },
                            },
                            [
                              _c("i", {
                                class: `iconfont icon_menu_2  ${
                                  child[_vm.iconKey]
                                }`,
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    slot: "title",
                                    "over-width": 100,
                                    content:
                                      _vm.$strings[child.code] ||
                                      child[_vm.labelKey],
                                  },
                                  slot: "title",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$strings[child.code] ||
                                          child[_vm.labelKey]
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c("sidebar-item", {
                            key: cindex,
                            attrs: {
                              menu: [child],
                              props: _vm.props,
                              screen: _vm.screen,
                              collapse: _vm.collapse,
                            },
                          }),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }