import request from '@/router/axios'
import axiosExtra from 'axios-extra'
export function addFile(obj) {
  return request({
    url: '/admin/sys-file/upload',
    method: 'post',
    data: obj
  })
}
export function delFile(obj) {
  return request({
    url: '/admin/sys-file/ossFile',
    method: 'delete',
    data: obj
  })
}
//分片上传终止 
export function stopFile(md5) {
  return request({
    url: '/admin/sys-file/tasks/abort/' + md5,
    method: 'post'
  })
}
//删除上传终止 即删除
export function deleteMd5File(md5) {
  return request({
    url: '/admin/sys-file/tasks/' + md5,
    method: 'delete'
  })
}


/**
 * 根据文件的md5获取未上传完的任务
 * @param identifier 文件md5
 * @returns {Promise<AxiosResponse<any>>}
 */
export function taskInfo(identifier) {
  return request({
    url: '/admin/sys-file/tasks/' + identifier,
    method: 'get',
    hidenProgress: true
  })
}
// const taskInfo = (identifier) => {
//   return http.get(`/admin/sys-file/tasks/${identifier}`)
// }

/**
 * 初始化一个分片上传任务
 * @param identifier 文件md5
 * @param fileName 文件名称
 * @param totalSize 文件大小
 * @param chunkSize 分块大小
 * @returns {Promise<AxiosResponse<any>>}
 */

export function initTask(obj) {
  return request({
    url: '/admin/sys-file/tasks',
    method: 'post',
    data: obj,
    hidenProgress: true,
    noloading: true
  })
}

/**
 * 获取预签名分片上传地址
 * @param identifier 文件md5
 * @param partNumber 分片编号
 * @returns {Promise<AxiosResponse<any>>}
 */
export function preSignUrl({ identifier, partNumber }) {
  return request({
    url: `/admin/sys-file/tasks/${identifier}/${partNumber}`,
    method: 'get',
    hidenProgress: true
  })
}

/**
 * 合并分片
 * @param identifier
 * @returns {Promise<AxiosResponse<any>>}
 */

export function merge(identifier) {
  return request({
    url: `/admin/sys-file/tasks/merge/${identifier}`,
    method: 'post',
    hidenProgress: true,
    noloading: true
  })
}
const httpExtra = axiosExtra.create({
  maxConcurrent: 5, //并发为1
  queueOptions: {
    retry: 3, //请求失败时,最多会重试3次
    retryIsJump: false //是否立即重试, 否则将在请求队列尾部插入重试请求
  }
})
export {
  httpExtra
}

