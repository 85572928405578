<template>
  <div class="layout_home" >
      <header class="layout_home_header">{{ $strings.quickly_entry }}</header>
      <div class="layout_icon_wrap">
        <div class="icon_item" v-for="(item) in hotMenus" :key="item.menuId" @click="goToRouter(item.path)">
          <i :class="`iconfont ${item.icon||'icon-shujuzhongxin2'}`" /><br/>
          <span>{{$strings[item.code] ||item.name}}</span>
        </div>
        
      </div>
      <hr/>
      <header class="layout_home_header">{{$strings.re_visit}}</header>
      <div class="layout_icon_wrap">
        <div class="icon_item" v-for="(item) in lastMenus" :key="item.menuId" @click="goToRouter(item.path)">
          <i :class="`iconfont ${item.icon||'icon-shujuzhongxin2'}`" /><br/>
          <span>{{$strings[item.code] ||item.name}}</span>
        </div>
      </div>
      <div class="layout_user cur_po" @click="getManual">
        <div class="user_left">
          <div class="project_name">Angel Watch</div>
          <div class="project_message">{{$strings.user_manual}}</div>
          <!-- <div class="project_dowload"><i :class="`iconfont icon-a-shenqingxiajiaxiaxian font_s_12`" />{{$strings.click_down}}</div> -->
        </div>
        <div class="user_right">
           <!-- <div class="project_name">AngelWatch</div> -->
          <div class="project_message">{{$strings.user_manual}}</div>
        </div>
      </div>
      <header class="layout_home_header just_between">
        <span>{{$strings.pda_active}}</span>
        <span class="font_primary cur_po font_s_14" @click="goToDetail">{{$strings.get_more}}</span>
      </header>
      <div class="pda_wrap">
        <div class="pda_item" v-for="(item) in list" :key="item.id" @click="gotoQuil(item.id)">
          <span class="message_pda" v-show="item.type===1">{{$strings.type_info}}</span>
          <span class="active_pda" v-show="item.type===2">{{$strings.type_active}}</span>
          <span class="title_pda" v-show="item.type===3">{{$strings.type_message}}</span>
          <div>{{item.title}}</div>
        </div>
      </div>
      <DialogDetail ref="dialog_detail" :title="$strings.info_list" :fetch-detail="fetchDetail" />
      <DialogQuil ref="dialog_quil" />
  </div>

  
</template>
  
<script>
import DialogDetail from './dialogDetail.vue'
import { getHotMenus, getLastMenus } from '@/api/admin/sort'
import { fetchList } from '@/api/admin/cement'
import {getStore} from '@/util/store'
import DialogQuil from './dialogQuil.vue'
import {mapGetters} from 'vuex'
export default {
  name: 'Home',
  components: {
    DialogDetail,
    DialogQuil
  },
  props: {
   
  },
  data() {
    return {
      lastMenus: [],
      hotMenus: [],
      list:[]
    }
  },
  computed: {
    ...mapGetters(['website','menu'])
  },
  watch: {},
  created() {
    this.fetchLast()
    this.fetchHot()
    this.getList()
  },
  mounted() {
    
  },
  methods: {
    fetchLast() {
      getLastMenus().then(res => {
        this.lastMenus = res.data.data
      }).catch(error=>{})
    },
    fetchHot() {
      getHotMenus().then(res => {
        this.hotMenus = res.data.data
      }).catch(error=>{})
    },
    goToRouter(path) {
      this.$router.push({path})
    },
    getManual() {
      const firstMenu = this.menu[0]
      let path = ''
      if (firstMenu.children.length) {
        path = firstMenu.children[0].path
      } else {
        path = firstMenu.path
      }
      window.open(`${window.location.origin}/#/docs/${getStore({ name: 'langCode' }).includes('zh')?'zh':'en'}${path}`)
    },
    getList() {
      fetchList({
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
        isAsc: false, // 是否倒序
        langCode:getStore({ name: 'accept' })
      }).then(res => {
        this.list = res.data.data.records
      }).catch(error=>{})
    },
    //弹窗列表函数抛出
    fetchDetail(val) {
      return fetchList(val)
    },
    goToDetail() {
      this.$refs.dialog_detail.show()
    },
    gotoQuil(id) {
      this.$refs.dialog_quil.show(id)
    }
  }
}
</script>
  <style lang="scss" >
  </style>
