var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom-end",
        "popper-class": "top_tag",
        trigger: "click",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "lang_choose m_r_16",
          attrs: { slot: "reference" },
          on: { click: _vm.changeIcon },
          slot: "reference",
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.langLabel))]),
          _c("tms-icon", {
            attrs: {
              "other-class": "choose_right",
              type: _vm.isOpen ? "shang" : "xia",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "lang_content" },
        _vm._l(_vm.langList, function (item) {
          return _c(
            "p",
            {
              key: item.accept,
              class: `language_item ${
                _vm.accept === item.accept ? "active_color" : ""
              }`,
              on: {
                click: function ($event) {
                  return _vm.changeLangCode(item.langCode)
                },
              },
            },
            [_c("span", [_vm._v(_vm._s(item.label))])]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }