var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    ref: "echarts",
    style: { height: _vm.heightNum + "px", width: "100%" },
    attrs: { id: "echarts" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }