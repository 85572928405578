

const requireComponents = require.context(//读写文件
  './',
  true,
  /.(vue)$/
)

var fileArr = [] 
requireComponents.keys().forEach(fileName => {
  if(!fileName.includes('index'))return
  const componentConfig = requireComponents(fileName)//获取组件下的index 统一注册
  let compName = fileName.split("/")[fileName.split("/").length - 2];
  if (componentConfig.default) {
    fileArr.push({
        name: `tms-${compName}`,
        compnent: componentConfig.default
    })
  }
})
export default fileArr;
